* {
  -webkit-font-smoothing: antialiased;
}
.section__container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 50px;

  @media (max-width: 1280px) {
    max-width: 1140px;
    padding: 0 24px;
  }
}

a {
  color: inherit !important;
  text-decoration: none;
  font-family: inherit;
}

.section__container--small {
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;

  .alternative_header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 792px;

    h2 {
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 46.8px */
      letter-spacing: -0.558px;

      @media (max-width: 992px) {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 36.4px */
        letter-spacing: -0.14px;
      }
    }

    &-caption {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
    }

    &-description {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
    }
  }

  @media (max-width: 1280px) {
    padding: 0 24px;
  }
}

.section__container--med {
  max-width: 1350px;
  margin: 0 auto;

  @media (max-width: 1280px) {
    padding: 0 24px;
  }
}

$color--bottle-green: #002726;
$color--cascade: #97a7a6;
$color--mint: #33c375;
$color--white: #ffffff;
$color--cream: #f0e8d6;
$color--grey-6: rgb(3, 10, 33, 3%);
$color--grey-5: rgba(3, 10, 33, 0.5);
$color--grey-4: rgba(3, 10, 33, 0.102);
$color--grey-3: rgb(3, 10, 33, 20%);
$color--grey-2: rgb(3, 10, 33, 30%);
$color--grey-1: rgb(3, 10, 33, 50%);

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: $color--grey-4;
}

.heading__secondary {
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 46.8px */
  letter-spacing: -0.558px;

  @media (max-width: 992px) {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 36.4px */
    letter-spacing: -0.14px;
  }
}

.heading__tertiary {
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 38.4px */
  letter-spacing: -0.496px;

  @media (max-width: 992px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 28.8px */
    letter-spacing: -0.372px;
  }
}

.subheading__secondary {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */

  @media (max-width: 992px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
  }
}

.subheading__secondary--cap {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  text-transform: uppercase;
  color: #002726;
  opacity: 1;

  @media (max-width: 992px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
  }
}

body {
  color: $color--bottle-green;
}

// Section Navbar
.section__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: $color--white;
  z-index: 9999;

  &--colored {
    background-color: #fffaf1;
  }

  &-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-block: 27px;
    height: 101px;
  }

  &-left {
    display: flex;
    align-items: center;
    gap: 32px;
  }

  &-logo {
    width: 108px;
    height: 48px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &-button {
    padding: 0px 20px;
    height: 40px;
    color: $color--bottle-green;
    border: 1px solid $color--bottle-green;
    display: flex;
    align-items: center;
    font-family: inherit;
    justify-content: center;
    gap: 4px;
    background-color: transparent;
    border-radius: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.4s;

    &:hover {
      background-color: $color--bottle-green;
      color: $color--white !important;
    }

    &--dropdown {
      margin-right: 13px;
      margin-left: 20px;
      padding: 0 38px 0 20px;
      position: relative;

      @media (max-width: 992px) {
        display: none;
      }

      &:hover {
        background-color: $color--white;
        color: $color--bottle-green !important;
      }
    }
  }

  &-right {
    display: flex;
    align-items: center;
    gap: 20px;

    a.section__navbar-button {
      @media (max-width: 992px) {
        display: none;
      }
    }

    .section__navbar-button--menu {
      display: none;
      padding: 0 10px 0 20px;

      &:hover {
        background-color: $color--white;
        color: $color--bottle-green !important;
      }

      @media (max-width: 992px) {
        display: flex;
        height: 35px;
        padding: 9px 12px;
        max-width: 90px;
        gap: 10px;
      }
    }

    .section__navbar-button--sidebar {
      display: none;
      height: 100%;
      flex-direction: column;
      gap: 4px;
      padding: 0;
      width: 35px;
      height: 35px;
      border: none;
      span {
        display: block;
        width: 12px;
        height: 1.5px;
        background-color: $color--bottle-green;
        border-radius: 2px;
        transition: all 0.4s;
      }

      &:hover {
        background-color: $color--white;
      }

      @media (max-width: 992px) {
        display: flex;
      }
    }
  }

  &-dropdown {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 1s cubic-bezier(0.23, 1, 0.32, 1);

    &-container {
      position: fixed;
      top: 93px;
      left: calc(50% - 640px);
      z-index: 1000;
      // top: calc(100% + 20px);
      // left: -100%;
      translate: 0 -40px;
      width: 100%;
      transform-origin: top center;
      max-width: 1280px;
      height: calc(100vh - 200px);
      max-height: 700px;
      background-color: #ffffff;
      border-radius: 12px;
      display: grid;
      overflow: hidden;
      grid-template-columns: 1.3fr 2fr 1.7fr;
      transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);

      @media (max-width: 1340px) {
        max-width: unset;
        left: 20px;
        width: calc(100% - 40px);
      }

      @media (max-width: 992px) {
        display: block;
        overflow-y: auto;
        min-height: calc(100vh - 101px);
        width: 100%;
        top: 101px;
        left: 0;
        border-radius: 0;

        &.column--open {
          .section__navbar-dropdown-container-header {
            display: flex;
          }

          .section__navbar-dropdown-column--1 {
            display: none;
          }

          .section__navbar-dropdown-column--2 {
            display: block;
          }

          .section__navbar-dropdown-column--3 {
            display: block;
          }
        }
      }

      &-header {
        display: none;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        padding: 0 24px;
        border-bottom: 1px solid $color--grey-4;
        border-top: 1px solid $color--grey-4;
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: $color--white;

        button {
          background: none;
          border: none;
          cursor: pointer;

          padding: 0;
          display: grid;
          place-content: center;
        }
      }
    }

    &-column {
      overflow-y: hidden;

      &--1 {
        display: flex;
        flex-direction: column;
        border-right: 1px solid $color--grey-4;

        @media (max-width: 992px) {
          border-top: 1px solid $color--grey-4;
          border-right: none;
        }

        .section__navbar-dropdown-link {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          padding: 8px 16px;
          border-radius: 8px;
          color: $color--bottle-green;
          text-decoration: none;
          transition: background 0.4s;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: transparent;
          border: none;
          font-family: inherit;
          cursor: pointer;

          &.active {
            background-color: #002e20;
            color: #ffffff;
            img {
              filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(156deg)
                brightness(116%) contrast(101%);
            }

            &:hover {
              background-color: #002e20;
              color: #ffffff;
            }
          }

          &:hover {
            background: #030a210d;
          }

          &-img {
            width: 24px;
            height: 24px;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      &-group {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 20px;
        border-bottom: 1px solid $color--grey-4;
      }

      &-content {
        padding: 20px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 12px;

        height: calc(100% - 82.4px);

        @media (max-width: 1340px) {
          padding: 12px;
        }

        @media (max-width: 992px) {
          height: auto;
          // padding-bottom: 100px;
        }
      }

      &--2 {
        border-right: 1px solid $color--grey-4;
        position: relative;

        @media (max-width: 992px) {
          display: none;
          border: none;
        }

        .section__navbar-dropdown-link {
          display: grid;
          grid-template-columns: 118px 1fr;
          padding: 12px;
          border-radius: 12px;
          gap: 16px;
          text-decoration: none;
          color: $color--bottle-green;

          &:hover {
            background-color: #030a210d;
          }

          &-img {
            width: 100%;
            height: 70px;
            border-radius: 12px;
            overflow: hidden;
            min-width: 70px;
            position: relative;
            background-color: #002726;
            // max-width: 118px;

            &-img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: bottom;
              z-index: 999;
              position: absolute;
              bottom: -19%;
              left: 0;
              filter: grayscale(100%);
              scale: 1.35;
            }
          }

          &-bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            object-fit: cover;
          }

          &-stats {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 16px;
            margin-top: auto;
            position: relative;

            @media (max-width: 1240px) {
              display: none;
            }

            @media (max-width: 992px) {
              display: flex;
            }

            @media (max-width: 576px) {
              display: none;
            }

            &-item {
              display: flex;
              align-items: center;
              gap: 4px;

              p {
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                white-space: nowrap;
                opacity: 0.9;
                text-transform: capitalize;
              }

              &-icon {
                width: 15px;
                height: 15px;
                display: grid;
                place-items: center;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }
          }

          &-text {
            display: flex;
            flex-direction: column;
            gap: 4px;

            h5 {
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
            }

            p {
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
            }
          }
        }
      }

      &--3 {
        @media (max-width: 992px) {
          display: none;

          .section__navbar-dropdown-column-header {
            border-top: 1px solid $color--grey-4;
          }
        }
        .section__navbar-dropdown-link {
          text-decoration: none;
          color: $color--bottle-green;
          padding: 12px;
          display: flex;
          flex-direction: column;
          gap: 4px;

          h5 {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
          }

          p {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            opacity: 0.9;
          }
        }
      }

      &-header {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        position: sticky;
        z-index: 10;
        top: 0;
        background: #ffffff;
        border-bottom: 1px solid $color--grey-4;

        @media (max-width: 992px) {
          border-bottom: none;
          padding: 24px;
          position: unset;
          top: unset;
        }
      }

      &-heading {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        color: $color--bottle-green;
      }

      &-subheading {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        opacity: 0.9;
        color: $color--bottle-green;
      }
    }

    &-group {
      position: relative;

      a {
        opacity: 0;
        translate: 0 -20px;
        transition: 0.4s;
      }

      button.section__navbar-dropdown-link {
        opacity: 0;
        translate: 0 -20px;
        transition: 0.4s;
      }

      &.open {
        .section__navbar-dropdown-overlay {
          visibility: visible;
          opacity: 1;
          pointer-events: all;

          @media (max-width: 992px) {
            display: none;
          }
        }

        .section__navbar-dropdown {
          visibility: visible;
          opacity: 1;
          pointer-events: all;

          &-container {
            translate: 0 0;
          }
        }

        .section__navbar-button--dropdown {
          z-index: 1000;
          background-color: $color--white;
          border-color: transparent;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -85%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            content: url("/images/new/arr.svg");
            width: 20px;
            height: 20px;
            z-index: 100000;
          }
        }

        @for $i from 1 through 1000 {
          a:nth-child(#{$i}) {
            transition: background 0.2s, opacity 0.4s $i * 0.03s,
              translate 0.4s $i * 0.03s;
            opacity: 1;
            translate: 0 0;
          }

          button.section__navbar-dropdown-link:nth-child(#{$i}) {
            transition: background 0.2s, opacity 0.4s $i * 0.03s,
              translate 0.4s $i * 0.03s;
            opacity: 1;
            translate: 0 0;
          }
        }
      }
    }

    &-overlay {
      position: fixed;
      inset: 0;
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
      z-index: 100;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.6);
      transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
    }
  }
}

// Section Hero
.section__hero {
  &-brands {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--2 {
    .section__hero-banner {
      background-color: #001a19;
      color: #ffffff;
      gap: 24px;

      @media (max-width: 992px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        padding: 24px;
        border-radius: 16px;
      }
    }
  }

  &-banner {
    grid-column: span 3;
    background-color: $color--bottle-green;
    color: $color--cream;
    height: auto;
    align-items: center;
    width: 100%;
    border-radius: 24px;
    position: relative;
    height: 403px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 0px 60px;
    overflow: hidden;
    margin-top: 36px;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      padding: 40px 24px;
      height: auto;
      margin-top: 40px;
    }

    @media (max-width: 576px) {
      border-radius: 16px;
    }

    &-svg {
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 16px;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      z-index: 999;
      max-width: 490px;
    }

    h1 {
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.62px;

      @media (max-width: 992px) {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 36.4px */
        letter-spacing: -0.14px;
      }
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
      }
    }

    &-texture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      object-fit: cover;
      z-index: -999;
      pointer-events: none;
    }

    &-grid {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 99;
      pointer-events: none;
    }

    &-buttons {
      display: flex;
      align-items: center;

      gap: 20px;
      margin-top: 24px;

      @media (max-width: 992px) {
        margin-top: 8px;
      }

      @media (max-width: 576px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
      }

      button {
        margin: 0 !important;
      }
    }

    &-price {
      display: flex;
      flex-direction: column;

      @media (max-width: 576px) {
        flex-direction: row;
        gap: 8px;
        align-items: flex-end;
      }

      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19.6px;
      }

      p {
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.496px;

        @media (max-width: 992px) {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 28.8px */
          letter-spacing: -0.372px;
        }
      }
    }

    &-gradient {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 0;
      pointer-events: none;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;
      height: 53px;
      cursor: pointer;
      padding: 17px 40px 17px 20px;
      border-radius: 12px;
      border: 2px solid rgba(0, 39, 38, 0.3);
      background: #33c375;
      font-size: 16px;
      font-family: inherit;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      text-decoration: none;
      color: #002726;
      margin-top: 16px;
      position: relative;
    }

    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 99;

      @media (max-width: 992px) {
        display: none;
      }
    }

    &-video {
      border-radius: 16px;
      border: 1px solid #97a7a6;
      width: 446px;
      height: 331px;
      position: relative;
      background-color: #fffaf1;
      overflow: hidden;

      @media (max-width: 992px) {
        height: 279px;
        width: 100%;
      }

      button {
        background-color: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(1.875px);
        width: 52px;
        height: 52px;
        padding: 0;
        border-style: none;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center center;
        transition: all 0.4s;
        cursor: pointer;
        margin: 0;
        display: grid;
        place-content: center;

        svg {
          transform: translate(0.05rem, 0.05rem);
        }

        &:hover {
          background-color: rgba(255, 255, 255);
        }
      }
    }
  }

  &-lerners {
    grid-column: span 2;
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    border: 1px solid rgba(0, 39, 38, 0.2);
    height: 293px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 992px) {
      padding: 24px;
      height: 240px;
    }
    @media (max-width: 576px) {
      height: auto;
      gap: 24px;
      border-radius: 16px;
    }

    &-dots {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
    }

    &-text {
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: column;
      gap: 8px;

      h2 {
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.496px;
        display: flex;
        align-items: center;

        @media (max-width: 992px) {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 28.8px */
          letter-spacing: -0.372px;
        }
      }

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;

        @media (max-width: 992px) {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 19.2px */
        }
      }
    }

    &-marquee {
      position: relative;
      z-index: 3;

      &-slider {
        display: flex;
        align-items: center;
        gap: 20px;
        width: max-content;
        animation: marquee 65s linear infinite;
        // animation-play-state: paused;

        // &:hover {
        //   animation-play-state: running;
        // }

        @media (max-width: 992px) {
          gap: 16px;
        }
      }

      &-card {
        border: 1px solid #030a211a;
        background-color: #ffffff;
        border-radius: 8px;
        width: 88px;
        min-width: 88px;
        height: 108px;
        overflow: hidden;

        @media (max-width: 992px) {
          width: 65px;
          height: 80px;
          min-width: 65px;
        }

        &-img {
          width: 100%;
          height: 75%;
          overflow: hidden;

          img {
            width: 100%;
            height: auto;
            object-fit: cover;
            // margin-top: 5px;
          }
        }

        &-brand {
          height: 25%;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 64%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  &-brands {
    height: 293px;
    display: grid;
    place-items: center;
    border-radius: 24px;
    border: 1px solid #00272633;
    background: #ffffff;

    &-lottie {
      width: 400px;
      height: 240px;

      @media (max-width: 576px) {
        width: 240px;
        height: 147px;
        scale: 1.3;
      }
    }

    @media (max-width: 576px) {
      height: 147px;
      border-radius: 16px;
    }
  }
}

@keyframes marquee {
  to {
    transform: translateX(calc(-50% - 10px));
  }
}

.section__marquee {
  &-container {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;

    @media (max-width: 1340px) {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
      margin-bottom: 40px;
    }
  }

  &-numbers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24.75px;
    grid-column: span 2;

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      gap: 16px;
    }

    &-card {
      background-color: #fffaf1;
      border: 1px solid rgba(151, 167, 166, 0.4);
      border-radius: 24px;
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      align-items: center;
      position: relative;
      overflow: hidden;
      transition: all 0.4s;
      min-height: 134.25px;
      max-height: 134.25px;

      @media (max-width: 767px) {
        min-height: 97px;
        max-height: 97px;
        border-radius: 16px;
        background-color: #002726;
        h4 {
          color: $color--mint;
        }

        p,
        h5 {
          color: white;
        }

        height: 97px;
        align-items: center;
      }

      &-img {
        width: fit-content;
        height: 100%;
        margin-left: auto;
        display: grid;
        grid-template-columns: 64px 64px;
        padding-right: 10px;
        gap: 16px;
        overflow: hidden;
        @media (max-width: 767px) {
          gap: 0;
          padding-right: 0 !important;
        }

        &-group {
          position: relative;
          width: 100%;
          height: 100%;

          &--2 {
            transform: translateY(12%);
            @media (max-width: 767px) {
              padding-right: 18px;
            }
          }
        }

        @keyframes brandsMoveUpMarquee {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(calc(-50% - 5px));
          }
        }

        @keyframes brandsMoveDownMarquee {
          0% {
            transform: translateY(calc(-100% + 134px));
          }
          100% {
            transform: translateY(calc(-50% + 134px));
          }
        }

        &-slider {
          height: 874px;

          &--1 {
            animation: brandsMoveUpMarquee 12s linear infinite;
            animation-play-state: paused;
            @media (max-width: 767px) {
              animation-play-state: running;
            }
          }

          &--2 {
            animation: brandsMoveDownMarquee 12s linear infinite;
            animation-play-state: paused;
            @media (max-width: 767px) {
              animation-play-state: running;
            }
          }
        }

        &-black {
          opacity: 1;

          @media (max-width: 767px) {
            opacity: 0;
          }
        }

        &-color {
          opacity: 0;

          @media (max-width: 767px) {
            opacity: 1;
          }
        }

        img {
          position: absolute;
          top: 10px;
          left: 50%;
          position: absolute;
          translate: -50%;
          transition: all 0.4s;
          @media (max-width: 767px) {
            width: 40px;
          }
        }
      }

      &-tabs {
        position: relative;
        width: 100%;
        height: 100%;
        mask-image: linear-gradient(
          to bottom,
          transparent 0%,
          #000000 30%,
          #000000 70%,
          transparent 100%
        );

        @media (max-width: 767px) {
          mask-image: none !important;
        }
        &-slider {
          position: absolute;
          right: 20px;
          top: -7px;
          display: flex;
          gap: 8px;
          flex-direction: column;
          // animation: careerMoveUpMarquee 6s linear infinite;
          @media (max-width: 767px) {
            top: -25px;
          }
        }

        @keyframes careerMoveUpMarquee {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(calc(-50% - 5px));
          }
        }
      }

      &--4 {
        grid-template-columns: auto 1fr;
        @media (max-width: 767px) {
          grid-template-columns: 0.6fr 1fr;
        }
        @media (max-width: 400px) {
          grid-template-columns: 0.6fr 1fr;
        }
      }

      &-tab {
        padding: 0 14px;
        height: 43px;
        font-size: 16px;
        white-space: nowrap;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-radius: 12px;
        transition: all 0.4s;
        @media (max-width: 420px) {
          max-width: 175px;
        }
        @media (max-width: 400px) {
          max-width: 188px;
        }
        @media (max-width: 380px) {
          max-width: 182px;
          font-size: 15px;
        }
        &.active {
          border-color: $color--bottle-green;
          background-color: $color--bottle-green;
          color: white;

          @media (max-width: 767px) {
            border-color: #ffffff;
            background-color: #ffffff;
            color: $color--bottle-green;
            transform: scale(0.95);
          }
        }

        @media (max-width: 767px) {
          border-color: #ffffff;
          background-color: transparent;
          color: #ffffff;
        }

        // &--1,
        // &--3 {
        //   border-color: #97a7a64d;
        //   background-color: #fffaf1;
        //   color: $color--bottle-green;

        //   @media (max-width: 767px) {
        //     border-color: #ffffff;
        //     background-color: #002726;
        //     color: #ffffff;
        //   }
        // }
        // &--2 {
        //   border-color: $color--bottle-green;
        //   background-color: $color--bottle-green;
        //   color: white;

        //   @media (max-width: 767px) {
        //     border-color: #ffffff;
        //     background-color: #ffffff;
        //     color: $color--bottle-green;
        //   }
        // }
      }

      &-svg {
        align-self: flex-end;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        position: relative;
        padding-right: 24px;

        @media (max-width: 767px) {
          height: 97px;
          padding-right: 8px;
        }

        @media (max-width: 992px) {
          padding-top: 11px;
        }

        &--2 {
          &::before {
            position: absolute;
            content: "";
            width: 50px;
            height: 50px;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            transform: translate(-50%, -50%) scale(0);
            filter: blur(12px);
            z-index: 1;
            transform-origin: 50% 50%;
            transition: all 0.4s;
            background-color: $color--mint;

            @media (max-width: 767px) {
              transform: translate(-50%, -50%) scale(2.5);
            }
          }
        }

        svg {
          position: relative;
          z-index: 2;

          @media (max-width: 767px) {
            height: 100%;
          }
        }

        .svg--1 {
          .bar {
            &--1 {
              transition: all 0.4s;

              fill: #d4d4d4;
            }

            &--2 {
              transition: all 0.4s;

              fill: #a8a8a8;
            }

            &--3 {
              transition: all 0.4s;

              fill: #a0a0a0;
            }
          }
          @media (max-width: 767px) {
            .bar {
              &--1 {
                fill: #bdedd5;
              }

              &--2 {
                fill: #c0efd8;
              }

              &--3 {
                fill: #33c375;
              }
            }
          }
        }

        .svg--2 {
          .el {
            &--1 {
              fill: #b9b8b7;
              transition: all 0.4s;
            }

            &--2 {
              stroke: #97a7a6;
              transition: all 0.4s;
            }

            &--3 {
              fill: #d3d2d1;
              transition: all 0.4s;
            }
            &--4 {
              fill: #a4a4a4;
              transition: all 0.4s;
            }
            &--5 {
              stroke: #5c5c5c;
              transition: all 0.4s;
            }
            &--6 {
              stroke: #d7d6d5;
              transition: all 0.4s;
            }
          }

          @media (max-width: 767px) {
            .el {
              &--1 {
                fill: #002220;
              }

              &--2 {
                stroke: #1d5e5c;
              }

              &--3 {
                fill: #133635;
              }
              &--4 {
                fill: #33c375;
              }
              &--5 {
                stroke: #33c375;
              }
              &--6 {
                stroke: #004e4c;
              }
            }
          }
        }
      }

      &:hover {
        background-color: #002726;
        h4 {
          color: $color--mint;
        }

        p,
        h5 {
          color: white;
        }

        .section__marquee-numbers-card-svg--2 {
          &::before {
            transform: translate(-50%, -50%) scale(2.5);
          }
        }

        .section__marquee-numbers-card-img-black {
          opacity: 0;
        }

        .section__marquee-numbers-card-img-color {
          opacity: 1;
        }

        .section__marquee-numbers-card-tabs {
          // mask-image: linear-gradient(
          //   to bottom,
          //   transparent 0%,
          //   #000000 50%,
          //   #000000 50%,
          //   transparent 100%
          // );
        }

        .section__marquee-numbers-card-tab.active {
          border-color: #ffffff;
          background-color: #ffffff;
          color: $color--bottle-green;
        }

        .section__marquee-numbers-card-tab {
          border-color: #ffffff;
          background-color: transparent;
          color: #ffffff;
        }

        .section__marquee-numbers-card-img-slider {
          &--1 {
            animation-play-state: running;
          }

          &--2 {
            animation-play-state: running;
          }
        }

        // .section__marquee-numbers-card-tab--1,
        // .section__marquee-numbers-card-tab--3 {
        //   border-color: white;
        //   background-color: $color--bottle-green;
        //   color: white;
        // }

        .svg--1 {
          .bar {
            &--1 {
              fill: #bdedd5;
            }

            &--2 {
              fill: #c0efd8;
            }

            &--3 {
              fill: #33c375;
            }
          }
        }

        .svg--2 {
          .el {
            &--1 {
              fill: #002220;
            }

            &--2 {
              stroke: #1d5e5c;
            }

            &--3 {
              fill: #133635;
            }
            &--4 {
              fill: #33c375;
            }
            &--5 {
              stroke: #33c375;
            }
            &--6 {
              stroke: #004e4c;
            }
          }
        }
      }

      &-texture {
        position: absolute;
        top: 0;
        left: 0;
        width: 300%;
        height: 300%;
        object-fit: cover;
        z-index: 999;
        pointer-events: none;
        mix-blend-mode: soft-light;
      }

      &-text {
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        @media (max-width: 1200px) {
          padding: 0 24px;
        }
        @media (max-width: 400px) {
          padding: 0 12px 0 24px;
        }
        h4 {
          font-size: 32px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 38.4px */
          letter-spacing: -0.496px;
          display: flex;
          align-items: center;
          transition: all 0.4s;

          @media (max-width: 1200px) {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%; /* 31.2px */
            letter-spacing: -0.12px;
          }
        }

        h5 {
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 24px */
          transition: all 0.4s;
          max-width: 110px;
          width: 100%;

          @media (max-width: 1200px) {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%; /* 20.8px */
          }
        }

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 135%; /* 18.9px */
          letter-spacing: 0.497px;
          text-transform: uppercase;
          transition: all 0.4s;

          @media (max-width: 1200px) {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 135%; /* 16.2px */
            letter-spacing: 0.42px;
          }
        }
      }
    }
  }

  &--2 {
    .section__hero-brands {
      background-color: #fffaf1;
      position: relative;
      overflow: hidden;

      &-texture {
        position: absolute;
        top: 0;
        left: 0;
        width: 300%;
        height: 300%;
        object-fit: cover;
        z-index: 999;
        pointer-events: none;
        mix-blend-mode: soft-light;
      }
    }
  }
}

// Section Programs
.section__programs {
  padding: 120px 0 0 0;

  @media (max-width: 992px) {
    padding: 40px 0 0 0;
  }

  &-container {
    display: flex;
    flex-direction: column;
    // gap: 12px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    h2 {
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 46.8px */
      letter-spacing: -0.558px;
      color: $color--bottle-green;

      @media (max-width: 992px) {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 36.4px */
        letter-spacing: -0.14px;
      }
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;
      color: $color--bottle-green;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
    }
  }

  &-tabs {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 20px;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 992px) {
      gap: 16px;
    }
  }

  &-tab {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid $color--bottle-green;
    display: grid;
    place-items: center;
    background-color: transparent;
    font-family: inherit;
    cursor: pointer;
    color: $color--bottle-green;
    white-space: nowrap;
    height: 52px;
    transition: all 0.4s;

    &:hover {
      background-color: #fffaf1;
    }

    &.active {
      background-color: $color--bottle-green;
      color: $color--white;
    }

    @media (max-width: 992px) {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      padding: 12px 10px;
      height: 41px;
    }
  }

  .swiper-slide {
    width: fit-content !important;
  }

  &-slider {
    margin-top: 32px;

    @media (max-width: 767px) {
      padding: 0 24px;
      margin-top: 24px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;

      h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 24px */

        @media (max-width: 992px) {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 19.2px */
        }
      }
    }

    &-swiper {
      overflow-x: hidden;

      padding-left: calc((100vw - 1150px) / 2) !important;

      @media (max-width: 1280px) {
        padding-left: calc((100vw - 1092px) / 2) !important;
      }

      @media (max-width: 1140px) {
        padding-left: 24px !important;
      }

      @media (max-width: 767px) {
        display: none !important;
      }

      &-mobile {
        display: none;

        @media (max-width: 767px) {
          display: flex;
          flex-direction: column;
          gap: 24px;
          align-items: center;
        }
      }

      &-button {
        display: none;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding: 20px;
        border-radius: 12px;
        border: 1px solid $color--bottle-green;
        background-color: transparent;
        color: $color--bottle-green;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        cursor: pointer;
        font-family: inherit;
        text-decoration: none;
        margin-top: 24px;
        width: 100%;

        @media (max-width: 767px) {
          display: flex;
          height: 59px;
        }
      }
    }

    &-container {
      margin-top: 60px;

      @media (max-width: 992px) {
        margin-top: 36px;
      }
    }

    &-slide {
      min-height: 316px;
      width: 800px;
      border-radius: 20px;
      border: 1px solid $color--bottle-green;
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      padding: 20px;
      gap: 24px;
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        background-color: #fffaf1;

        .section__programs-slider-slide-header-stats--hover {
          opacity: 1;
        }
      }

      @media (max-width: 992px) {
        display: flex;
        flex-direction: column;
        width: 327px;
        height: 636px;
        padding: 16px;
        border-radius: 16px;
      }

      @media (max-width: 767px) {
        width: 100%;
        height: auto;
      }

      // &-img {
      //   width: 100%;
      //   height: 100%;
      //   overflow: hidden;
      //   border-radius: 16px;
      //   border: 1px solid rgba(255, 255, 255, 0.6);
      //   // background: linear-gradient(126deg, #000000, #002726);
      //   position: relative;
      //   &-texture {
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     width: 100%;
      //     height: 100%;
      //     opacity: 0.2;
      //     object-fit: cover;
      //     z-index: 1;
      //   }

      //   img {
      //     width: 100%;
      //     height: 100%;
      //     object-fit: contain;
      //     object-position: bottom;
      //     z-index: 999;
      //     position: absolute;
      //     bottom: -16%;
      //     filter: grayscale(100%);
      //     scale: 1.2;

      //     &.card-text-bg {
      //       z-index: 9;
      //       position: absolute;
      //       top: 0;
      //       left: 0;
      //       width: 100%;
      //       height: 100%;
      //       object-fit: cover;
      //     }
      //   }

      //   @media (max-width: 992px) {
      //     height: 295px;
      //     min-height: 295px;
      //     border-radius: 12px;
      //   }
      // }

      &-img {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        gap: 20px;

        &-badge {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 16.8px */
          padding: 5px 10px;
          border: 1px solid $color--bottle-green;
          border-radius: 7.5px;
          align-self: flex-start;
          display: none;

          @media (max-width: 992px) {
            display: flex;
          }
        }
      }

      &-header {
        display: flex;
        flex-direction: column;
        gap: 8px;

        h3 {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 31.2px */
          letter-spacing: -0.12px;
        }

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 22.4px */
          line-clamp: 3;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
        }

        &-stats {
          display: flex;
          align-items: center;
          gap: 20px;
          transition: all 0.4s;
          margin-top: 8px;

          &--hover {
            margin: 0;

            @media (max-width: 992px) {
              opacity: 1;
            }
          }

          &-item {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-top: 8px;

            p {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              text-transform: capitalize;
            }

            &-icon {
              width: 17px;
              height: 17px;
              display: grid;
              place-items: center;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }

      &-footer {
        display: flex;
        align-items: center;
        gap: 8px;

        &-images {
          display: flex;
          align-items: center;

          &-img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid $color--grey-1;
            display: grid;
            place-items: center;
            background-color: #fffaf1;
            position: relative;

            @media (max-width: 992px) {
              width: 32px;
              height: 32px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            &:nth-child(2) {
              margin-left: -16px;
            }

            &:nth-child(3) {
              margin-left: -16px;
            }

            &:nth-child(4) {
              margin-left: -16px;
              background-color: $color--bottle-green;
              border-color: $color--white;
              color: $color--white;
            }
          }
        }

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */

          span {
            font-weight: 500;
            display: block;
            margin-top: 4px;
          }
        }
      }
    }

    &-card {
      border: 1px solid $color--bottle-green;
      padding: 20px;
      border-radius: 20px;
      // height: 315px;
      width: 315px;
      transition: all 0.4s;
      background-color: #fff;
      cursor: pointer;
      aspect-ratio: 1/1;
      &:hover {
        background-color: #fffaf1;
      }

      @media (max-width: 767px) {
        width: 100%;
        border-radius: 16px;
        // height: 100%;
      }
      @media (max-width: 560px) {
        width: 100%;
        // max-width: 475px;
        // max-height: 475px;
        // height: 100%;
      }
      @media (max-width: 400px) {
        // width: 100%;
        //  max-width: 375px;
        //  max-height: 375px;
        // height: 100%;
      }
      &-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      &-header {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h3 {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 31.2px */
          letter-spacing: -0.12px;
        }

        &-price {
          color: #002726;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 24px */
        }
      }

      &-stats {
        display: flex;
        align-items: center;
        gap: 20px;
        transition: all 0.4s;

        &-item {
          display: flex;
          align-items: center;
          gap: 6px;

          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            text-transform: capitalize;
          }

          &-icon {
            width: 17px;
            height: 17px;
            display: grid;
            place-items: center;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      &-author {
        display: flex;
        align-items: center;
        gap: 8px;

        &-img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          border: 1px solid $color--grey-1;
          display: grid;
          place-items: center;
          background-color: $color--bottle-green;
          position: relative;

          @media (max-width: 992px) {
            width: 32px;
            height: 32px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &-text {
          display: flex;
          flex-direction: column;
          gap: 4px;
          text-transform: capitalize;

          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
          }

          span {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
          }
        }
      }
    }
  }

  &-arrows {
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: 992px) {
      display: none;
    }

    &-see_all{
      color: #9A9DA6 !important;
      text-decoration: underline;
      font-weight: 500;
      line-height: 120%;
      padding: 16px 20px;
    }
  }

  &-arrow {
    height: 38px;
    width: 38px;
    border-radius: 100px;
    border: 1px solid $color--bottle-green;
    display: grid;
    place-items: center;
    background-color: transparent;
    cursor: pointer;
    stroke: $color--bottle-green;
    transition: all 0.4s;

    &:hover {
      background-color: $color--bottle-green;
      stroke: $color--white;
    }

    &-prev {
      svg {
        transform: rotate(180deg);
      }
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  &-card-cta {
    position: relative;
    width: 100%;
    height: 150px;
    margin-top: 60px;
    padding: 0 24px;

    @media (max-width: 992px) {
      margin-top: 36px;
    }

    &-texture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      opacity: 0.2;
    }

    &-grid {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 2;
    }

    &-container {
      display: grid;
      grid-template-columns: 1fr auto;
      position: relative;
      z-index: 3;
      align-items: center;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;
      padding: 16px 32px;
      background: linear-gradient(90deg, #030a21 0%, #1543db 100%);
      background-size: 120% 100%;
      transition: background-position 0.4s;

      &:hover {
        background-position: 100% 0;
      }

      @media (max-width: 1280px) {
        max-width: 1092px;
      }

      @media (max-width: 992px) {
        padding: 16px 24px;
        border-radius: 12px;
      }
    }

    &-img {
      width: 88px;
      height: 88px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: $color--white;
      position: relative;
      z-index: 3;

      h3 {
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 38.4px */
        letter-spacing: -0.496px;

        @media (max-width: 992px) {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 28.8px */
          letter-spacing: -0.372px;
        }

        @media (max-width: 576px) {
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 20.8px */
        }
      }

      a {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
        color: currentColor;
        text-decoration: none;

        @media (max-width: 992px) {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 22.4px */
        }

        @media (max-width: 576px) {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 19.2px */
        }
      }
    }
  }
}

.section__advantages {
  padding: 80px 0;

  @media (max-width: 992px) {
    padding: 40px 0;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 992px) {
      gap: 28px;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    width: 100%;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
    }
  }

  .swiper-slide {
    width: fit-content !important;
  }

  &-slider {
    &-swiper {
      padding-left: calc((100vw - 1150px) / 2) !important;

      @media (max-width: 1280px) {
        padding-left: calc((100vw - 1092px) / 2) !important;
      }

      @media (max-width: 1140px) {
        padding-left: 24px !important;
      }
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }

    &-slide {
      height: 600px;
      position: relative;
      border-radius: 16px;
      width: 400px;
      overflow: hidden;
      padding: 32px 24px;
      border: 1px solid rgba(0, 39, 38, 0.4);
      background-image: linear-gradient(126deg, #000000, #002726);

      @media (max-width: 992px) {
        width: 269px;
        height: 510px;
        border-radius: 12px;
      }

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 20;
      }

      &-texture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 20;
        opacity: 0.2;
      }

      &-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        gap: 12px;
        position: relative;
        z-index: 30;

        @media (max-width: 992px) {
          gap: 8px;
        }

        h4 {
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 24px */
          color: $color--white;

          @media (max-width: 992px) {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 19.2px */
          }
        }

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
          color: $color--white;

          @media (max-width: 992px) {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 16.8px */
          }
        }
      }
    }
  }
}

.section__learners {
  padding-top: 106px;

  @media (max-width: 992px) {
    padding-top: 40px;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 992px) {
      gap: 28px;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    width: 100%;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;
      margin-bottom: 12px !important;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
    }
  }

  &-arrows {
    display: flex;
    align-items: center;
    gap: 12px;

    @media (max-width: 992px) {
      display: none;
    }
  }

  &-arrow {
    height: 38px;
    width: 38px;
    border-radius: 100px;
    border: 1px solid $color--bottle-green;
    display: grid;
    place-items: center;
    background-color: transparent;
    cursor: pointer;
    stroke: $color--bottle-green;

    &:hover {
      background-color: $color--bottle-green;
      stroke: $color--white;
    }

    &-prev {
      svg {
        transform: rotate(180deg);
      }
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .swiper-slide {
    width: fit-content !important;
  }

  &-slider {
    margin-top: 40px;

    @media (max-width: 992px) {
      margin-top: 28px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }

    &-swiper {
      padding-left: calc((100vw - 1150px) / 2) !important;

      @media (max-width: 1280px) {
        padding-left: calc((100vw - 1092px) / 2) !important;
      }

      @media (max-width: 1140px) {
        padding-left: 24px !important;
      }

      @media (max-width: 992px) {
        display: none;
      }

      &--mobile {
        display: none;
        align-items: center;
        gap: 20px;
        padding: 0 24px;
        overflow-y: auto;
        scrollbar-width: none;

        @media (max-width: 992px) {
          display: flex;
        }
      }
    }

    &-slide {
      height: 313px;
      position: relative;
      border-radius: 16px;
      width: 241px;
      overflow: hidden;
      padding: 16px;
      border: 1px solid $color--cascade;
      display: flex;
      flex-direction: column;
      gap: 20px;
      background-color: white;

      @media (max-width: 992px) {
        border-radius: 12px;
      }

      &-header {
        display: flex;
        align-items: center;
        gap: 12px;

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 20.8px */
          width: min-intrinsic;
          width: -webkit-min-content;
          width: -moz-min-content;
          width: min-content;
          display: table-caption;
          display: -ms-grid;
          -ms-grid-columns: min-content;
        }
      }

      &-img {
        width: 48px;
        height: 48px;
        border-radius: 8px;
        overflow: hidden;
        background-color: $color--bottle-green;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-card {
        background-color: rgba(3, 10, 33, 0.03);
        padding: 12px;
        border-radius: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 19.6px */
        width: 100%;
        min-height: 89px;
        max-height: 89px;

        span {
          font-weight: 500;
          display: block;
          margin-top: 4px;
        }

        &-arrow {
          background-color: $color--bottle-green;
          width: 36px;
          height: 24px;
          display: grid;
          place-items: center;
          border-radius: 20px;
        }
      }

      &-cards {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 6px;

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */

          span {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 16.8px */
          }
        }
      }
    }
  }
}

.section__alumni {
  margin-top: 60px;

  &-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &-header {
    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 24px */

      @media (max-width: 992px) {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
    }
  }

  &-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-rows: 76px;
    gap: 10px;

    @media (max-width: 992px) {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      grid-auto-rows: 56px;
    }
  }

  &-card {
    display: grid;
    place-content: center;
    width: 100%;
    height: 100%;

    img {
      // width: 100%;
      height: 25.44px;
      object-fit: contain;

      @media (max-width: 768px) {
        width: 100%;
        /* height: 20px; */
        scale: 0.75;
      }

      @media (max-width: 576px) {
        width: 100%;
        /* height: 20px; */
        scale: 0.75;
      }
    }
  }
}

.section__testimonials {
  padding: 120px 0;

  @media (max-width: 992px) {
    padding: 40px 0;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 60px;

    @media (max-width: 992px) {
      gap: 32px;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
    }

    h2 {
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 46.8px */
      letter-spacing: -0.558px;

      @media (max-width: 992px) {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 36.4px */
        letter-spacing: -0.14px;
      }
    }
  }

  &-items {
    column-count: 4;
    column-gap: 24px;

    @media (max-width: 1200px) {
      column-gap: 16px;
    }

    @media (max-width: 992px) {
      column-count: 3;
    }

    @media (max-width: 768px) {
      column-count: 2;
    }

    @media (max-width: 576px) {
      column-count: 1;
    }
  }

  &-item {
    display: flex;
    padding: 40px 20px 20px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 29px;
    border-radius: 16px;
    border: 1px solid $color--cascade;
    background: #fcfcfc;
    position: relative;
    overflow: hidden;
    margin-bottom: 24px;
    text-decoration: none;
    color: $color--bottle-green;

    @media (max-width: 992px) {
      margin-bottom: 16px;
      border-radius: 12px;
    }

    @media (max-width: 992px) {
      &:last-child {
        margin-bottom: 0;
      }
    }

    &-text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      opacity: 0.7;
    }

    blockquote {
      margin: 0;
    }

    &-platform-logo {
      width: 42px;
      height: 42px;
      overflow: hidden;
      display: grid;
      place-items: center;
      position: absolute;
      top: 0px;
      right: 0px;

      svg {
        position: absolute;
        top: -2px;
        right: -2px;
      }

      img {
        width: 20px;
        height: 20px;
        object-fit: contain;
        position: relative;
        z-index: 1;
        translate: 2px -2px;
      }
    }

    &-author {
      display: flex;
      align-items: center;
      gap: 12px;

      &-img {
        width: 100%;
        max-width: 48px;
        height: 48px;
        border-radius: 8px;
        overflow: hidden;
        background-color: $color--bottle-green;

        @media (max-width: 992px) {
          width: 42px;
          height: 42px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-text {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
      &-name {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
      &-designation {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        opacity: 0.5;
        grid-column: 2;
      }
    }

    &--video {
      height: 340px;
      position: relative;
      background-color: $color--bottle-green;

      @media (max-width: 992px) {
        height: 400px;
      }

      &-footer {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin-top: auto !important;
        position: relative;
        z-index: 20;

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 19.2px */
          color: $color--white;
        }

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
          opacity: 0.5;
          color: $color--white;
        }
      }
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      z-index: 10;
      pointer-events: none;
      background-image: linear-gradient(
        to bottom,
        rgba(0 0 0/ 0) 0%,
        rgba(255, 255, 255, 0) 50%,
        rgba(0 0 0/ 1) 86%
      );
    }
  }

  &-button {
    border: 1px solid $color--bottle-green;
    padding: 20px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    font-family: inherit;
    background-color: transparent;
    align-self: center;
    cursor: pointer;
    transition: all 0.4s;
    color: #002726;

    &:hover {
      background-color: $color--bottle-green;
      color: $color--white;
    }

    @media (max-width: 772px) {
      width: 100%;
    }
  }

  &-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-button {
      position: absolute;
      top: 50%;
      left: 50%;
      translate: -50% -50%;
      border: none;
      background-color: transparent;
      cursor: pointer;
      z-index: 10;
      transition: all 0.3s;

      svg {
        pointer-events: none;
      }
    }
  }
}

.section__upskill {
  background-color: #fffaf1;
  padding: 80px 0;
  // margin-top: 115px;

  @media (max-width: 992px) {
    padding: 60px 0;
    margin-top: 0px;
  }

  @media (max-width: 576px) {
    padding: 40px 0 40px 0;
  }

  &--2 {
    @media (max-width: 992px) {
      background-color: #fff;
      padding: 40px 0;
    }
  }

  &-header {
    display: grid;
    grid-template-columns: 1fr auto;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      gap: 24px;

      .section__upskill-link {
        display: none;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 12px;

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
        text-transform: uppercase;

        @media (max-width: 992px) {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%; /* 22.4px */
        }
      }

      h2 {
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 46.8px */
        letter-spacing: -0.558px;

        @media (max-width: 992px) {
          font-size: 28px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 36.4px */
          letter-spacing: -0.14px;
        }
      }
    }

    &-desc {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 25.6px */
      margin-top: 16px !important;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 40px;

    &-text {
      h5 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
    }
  }

  &-content-grid {
    display: grid;
    row-gap: 40px;
    column-gap: 197px;

    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

    @media (max-width: 1140px) {
      column-gap: 100px;
    }

    @media (max-width: 992px) {
      gap: 24px;
      grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    }

    @media (max-width: 576px) {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }

    &-item {
      display: flex;
      align-items: flex-start;
      gap: 24px;

      @media (max-width: 992px) {
        // flex-wrap: wrap;
        gap: 16px;
      }

      &-text {
        display: flex;
        flex-direction: column;
        gap: 6px;

        h6 {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 19.2px */
          margin: 0;
        }

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 25.6px */
          margin: 0;
        }
      }

      &-img {
        width: 72px;
        min-width: 72px;
        height: 72px;
        border-radius: 12px;
        background-color: rgba(3, 10, 33, 0.05);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @media (max-width: 992px) {
          width: 52px;
          height: 52px;
          min-width: 52px;
        }

        img {
          width: 44px;
          height: 44px;
          object-fit: contain;

          @media (max-width: 992px) {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }

  &-brands {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    h5 {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
    }

    &-grid {
      column-gap: 20px;
      row-gap: 10px;
      display: grid;
      grid-template-columns: repeat(16, 40px);
      grid-template-rows: repeat(2, 40px);
      overflow-x: auto;
      scrollbar-width: none;

      @media (max-width: 992px) {
        column-gap: 12px;
        row-gap: 12px;
        grid-template-columns: repeat(9, 27px);
        grid-template-rows: repeat(4, 27px);
      }
      @media (max-width: 600px) {
        grid-template-columns: repeat(9, 31px);
        grid-template-rows: repeat(4, 31px);
      }
      @media (max-width: 400px) {
        grid-template-columns: repeat(9, 25px);
        grid-template-rows: repeat(4, 25px);
      }

      &-group {
        height: 100%;
        grid-row: span 2;
        grid-column: span 2;
        border-radius: 12px;
        border: 1px solid;

        @media (max-width: 992px) {
          border-radius: 6px;
        }

        &:nth-child(1) {
          background-color: #3686f71c;
          border-color: #3686f733;
        }

        &:nth-child(2) {
          background-color: #02b50d1c;
          border-color: #02b50d33;
        }

        &:nth-child(3) {
          background-color: #0064911c;
          border-color: #00649133;
          grid-column: span 3;
        }

        &:nth-child(4) {
          background-color: #b305051c;
          border-color: #b3050533;
        }

        &:nth-child(5) {
          background-color: #5225b51c;
          border-color: #5225b533;
          grid-row: 1;
          grid-column: 8 /11;

          @media (max-width: 992px) {
            grid-row: 3;
            grid-column: 1 / 4;
          }
        }

        &:nth-child(6) {
          background-color: #3395ff1c;
          border-color: #3395ff33;
          grid-row: 2;
          grid-column: 8 / 11;

          @media (max-width: 992px) {
            grid-row: 4;
            grid-column: 1 / 4;
          }
        }

        &:nth-child(7) {
          background-color: #0102021c;
          border-color: #01020233;
        }

        &:nth-child(8) {
          background-color: #ffd5001c;
          border-color: #ffd50033;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        overflow: hidden;

        @media (max-width: 992px) {
          border-radius: 6px;
        }

        p {
          display: none;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;

          @media (max-width: 992px) {
            display: none;
          }
        }

        img {
          width: 60%;
          height: 60%;
          object-fit: contain;

          // @media (max-width: 992px) {
          //   display: none;
          // }
        }

        // &:has(+ .section__upskill-brands-grid-item) {
        //   height: 50%;

        //   & + .section__upskill-brands-grid-item {
        //     height: 50%;
        //   }
        // }
      }
    }
  }

  &-link {
    border: 1px solid $color--bottle-green;
    padding: 18px 32px;
    border-radius: 12px;
    display: flex;
    height: 56px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    font-family: inherit;
    background-color: $color--white;
    align-self: center;
    cursor: pointer;
    text-decoration: none;
    color: $color--bottle-green;
    transition: all 0.4s;

    &:hover {
      background-color: $color--bottle-green;
      color: $color--white !important;
    }

    &-mobile {
      display: none;
      height: 59px;

      @media (max-width: 992px) {
        display: flex;
        margin-top: 60px;
      }
    }
  }
}

.section__tabs {
  padding: 120px 0;

  @media (max-width: 992px) {
    padding: 40px 0;
  }

  &-header {
    // gs-2071
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 72px;
    
    &-text {
      display: flex;
      flex-direction: column;
      gap: 12px;
      
      .heading__secondary {
        margin-bottom: 4px;
      }
      
      p {
        span {
          font-weight: 500;
          line-height: 120%; /* 19.2px */
          text-transform: uppercase;
        }
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
        margin-top: 4px !important;

        @media (max-width: 992px) {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 22.4px */
        }
      }
    }

    &-link {
      border: 1px solid $color--bottle-green;
      padding: 19px 30px 18px 31px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      font-family: inherit;
      background-color: $color--white;
      align-self: center;
      cursor: pointer;
      color: $color--bottle-green;
      text-decoration: none;
      text-align: center;
      white-space: nowrap;
      transition: all 0.4s;

      &:hover {
        background-color: $color--bottle-green;
        color: $color--white;
      }

      @media (max-width: 992px) {
        display: none;
      }
    }
  }

  &-group {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    margin-top: 32px;
    overflow: auto;

    @media (max-width: 992px) {
      gap: 16px;
      margin-top: 44px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    button {
      border: 1px solid $color--grey-4;
      padding: 12px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      font-family: inherit;
      background-color: $color--white;
      cursor: pointer;
      color: $color--bottle-green;
      text-decoration: none;
      text-align: center;
      white-space: nowrap;
      transition: all 0.4s;
      // border: 1px solid transparent;
      &:hover {
        background-color: #fffaf1 !important;
        // border-color:  $color--bottle-green !important;
      }
      &.active {
        // border:none;
        border: 1px solid $color--bottle-green;
        background-color: $color--bottle-green;
        color: $color--white;
        // font-weight: 500;
        &:hover {
          background-color: $color--bottle-green !important;
        }
      }
    }
  }

  &-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 187px);
    gap: 24px;
    margin-top: 36px;

    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
    }

    &-item {
      background-color: $color--bottle-green;
      position: relative;
      border-radius: 16px;
      overflow: hidden;

      &-texture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
        opacity: 0.2;
      }

      &-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-special {
        position: relative;
        background-color: $color--bottle-green;

        &-img {
          position: absolute;
          bottom: -12%;
          right: -64%;
          height: auto;

          @media (max-width: 992px) {
            right: -5%;
          }
        }

        &-texture {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          z-index: 1;
          opacity: 0.3;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &-text {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 30px 25px;
          z-index: 3;

          p {
            font-size: 70px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%; /* 91px */
            letter-spacing: -1px;
          }

          span {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 25.6px;
            // letter-spacing: 0.5px;
            // text-transform: uppercase;
            max-width: 100%;
            color: $color--white;
          }
        }
      }

      &-text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 2;
        text-transform: capitalize;
        color: $color--white;
        background-color: rgba($color--bottle-green, 0.8);
        backdrop-filter: blur(2px);

        h4 {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
          letter-spacing: 0.504px;
          text-transform: uppercase;
        }

        &-location {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
          letter-spacing: 0.504px;
          text-transform: uppercase;
        }

        &-icon {
          width: 16px;
          height: 16px;
          display: grid;
          place-items: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    &-item:nth-child(1) {
      grid-column: 1;
      grid-row: span 2;

      @media (max-width: 992px) {
        height: 386px;
      }
    }

    &-item:nth-child(2) {
      grid-column: 2;
      grid-row: span 1;
      @media (max-width: 992px) {
        height: 181px;
      }
    }

    &-item:nth-child(3) {
      grid-column: span 2;
      grid-row: span 2;

      @media (max-width: 992px) {
        height: 391px;
      }
    }

    &-item:nth-child(4) {
      grid-column: 1;
      grid-row: span 1;

      @media (max-width: 992px) {
        height: 186px;
      }
    }

    &-item:nth-child(5) {
      grid-column: 2;
      grid-row: 2 / -1;

      @media (max-width: 992px) {
        height: 319px;
      }
    }

    &-item:nth-child(6) {
      grid-column: span 2;
      grid-row: span 1;

      @media (max-width: 992px) {
        height: 181px;
      }
    }
  }
}

.section__influencers {
  padding: 80px 0;

  @media (max-width: 992px) {
    padding: 40px 0;
  }

  &-container {
    display: grid;
    grid-template-columns: 1.5fr 2fr;

    @media (max-width: 1140px) {
      grid-template-columns: 1fr;
      gap: 24px;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    gap: 12px;

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
    }

    h2 {
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 46.8px */
      letter-spacing: -0.558px;

      @media (max-width: 992px) {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 36.4px */
        letter-spacing: -0.14px;
      }
    }
  }

  &-slider {
    &-container {
      background-color: $color--bottle-green;
      height: 240px;
      width: 100%;
      border-radius: 16px;
      overflow: hidden;
      position: relative;

      @media (max-width: 768px) {
        height: 427px;
      }
    }

    &-card {
      display: grid;
      grid-template-columns: 280px 1fr;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      pointer-events: none;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
      }
    }

    &-texture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 1;
      opacity: 0.2;
      pointer-events: none;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-img {
      width: 84%;
      height: 94%;
      overflow: hidden;
      position: relative;
      z-index: 20;
      margin-top: auto;

      @media (max-width: 768px) {
        width: 280px;
        height: auto;
      }

      @media (max-width: 576px) {
        translate: -8% 4px;
        width: 220px;
      }
      @media (max-width: 380px) {
        translate: -25% 4px;
        width: 220px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        translate: 0 0;
        transition: all 1s;
        position: relative;
        z-index: 20;
      }

      span {
        position: absolute;
        content: "";
        width: 220px;
        height: 220px;
        top: 50%;
        left: 50%;
        opacity: 0.1;
        border-radius: 50%;
        transform: translate(-50%, -20%);

        -webkit-transform: translate(-50%, -20%);
        background-color: $color--white;
        z-index: -1;
      }
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding-top: 32px;
      padding-right: 32px;
      position: relative;

      @media (max-width: 992px) {
        padding: 34px 20px 20px 20px;
        gap: 16px;
      }

      &-quotes {
        position: absolute;
        left: -12px;
        top: 0px;
        translate: -100% 0%;

        @media (max-width: 992px) {
          left: 0;
          top: 0;
          translate: 0 -50%;

          svg {
            scale: 1.2;
          }
        }
      }

      blockquote {
        margin: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
        color: $color--white;
        position: relative;
      }

      &-author {
        display: flex;
        flex-direction: column;
        gap: 4px;
        transition: all 1s;

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 19.2px */
          color: $color--white;
        }

        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
          opacity: 0.6;
          color: $color--white;
          max-width: 160px;
        }
      }
    }

    &-buttons {
      display: flex;
      align-items: center;
      gap: 8px;
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 150px;
      // overflow: hidden;

      &-slider {
        width: 100%;
        display: flex;
      }

      @media (max-width: 576px) {
        gap: 0px;
        right: -12px;
        bottom: 10px;
      }
    }

    &-div {
      position: relative;
      width: 48px;
      min-width: 48px;
      -webkit-min-width: 48px;
      -moz--min-width: 48px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        span {
          background-color: rgba($color--white, 0.2);
        }

        // .section__influencers-slider-button-img {
        //   translate: 0 -10%;
        //   scale: 1.2;
        // }
        transform: scale(1.2) translate(0, -10%);
        -webkit-transform: scale(1.2) translate(0, -10%);
      }

      &-container {
        aspect-ratio: 1 / 1.6;
        -moz-aspect-ratio: 1 / 1.6;
        -webkit-aspect-ratio: 1 / 1.6;
        border-radius: 100px;
        overflow: hidden;
        display: grid;
        align-items: end;
        height: 46.47px;
        width: 36px;
        margin-left: 6px;
        margin-top: -48px;
        // background-color: #333333;
      }

      span {
        position: absolute;
        bottom: 2px;
        left: 50%;
        // translate: -50% 0;
        transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        width: 76%;
        border-radius: 50%;
        aspect-ratio: 1/1;
        -moz-aspect-ratio: 1/1;
        -webkit-aspect-ratio: 1/1;
        background-color: rgba($color--white, 0.1);
        transition: all 0.3s;
      }

      &-img {
        position: relative;
        width: 110%;
        left: 0%;
        height: auto;
        // scale: 1.1;
        transform: scale(1.15) translate(-5%, -1%);
        -webkit-transform: scale(1.15) translate(-5%, -1%);
        -moz-transform: scale(1.15) translate(-5%, -1%);
        // translate: 0 -5%;
        transition: all 0.3s;
        z-index: 999;
      }
    }
  }
}

.section__investors {
  background-color: #fffaf1;
  padding: 60px 0;

  @media (max-width: 992px) {
    padding: 40px 0 80px 0;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 65px;

    @media (max-width: 992px) {
      gap: 44px;
    }

    @media (max-width: 576px) {
      gap: 32px;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;

    p span {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 25.6px */
      margin-top: 4px !important;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
      }
    }
  }

  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      gap: 32px;
      flex-direction: column;
      align-items: flex-start;
    }

    &-brands {
      display: flex;
      flex-direction: column;
      gap: 25px;
      align-items: center;

      @media (max-width: 768px) {
        gap: 16px;
        flex-direction: row;
        flex-wrap: wrap;
      }

      @media (max-width: 576px) {
        gap: 12px;
      }
    }

    &-brand {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color--white;
      border: 1px solid $color--bottle-green;
      padding: 10px 20px;
      border-radius: 12px;
      width: 240px;
      height: 100px;

      @media (max-width: 768px) {
        width: 154px;
        height: 57px;
        padding: 10px 20px;
        border-radius: 8px;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    &-divider {
      width: 1px;
      height: 225px;
      background-color: rgba($color--bottle-green, 0.6);

      @media (max-width: 768px) {
        width: 100%;
        height: 1px;
      }
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      align-items: center;
      gap: 40px;
      position: relative;

      &::after {
        content: "";
        background-image: linear-gradient(
          to bottom,
          transparent 0%,
          #fffaf1 100%
        );
        width: 100%;
        height: 100%;
        position: absolute;
        display: none;

        @media (max-width: 768px) {
          display: flex;
        }
      }

      &-add-more {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        translate: 0 20%;
        position: absolute;
        display: none;
        z-index: 10;
        left: 50%;
        bottom: -40px;
        translate: -50% 0;
        background-color: transparent;
        border: none;
        font-size: 14px;
        color: inherit;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        font-family: inherit;

        &-non-clickable {
          pointer-events: none;
        }

        @media (max-width: 768px) {
          display: flex;
        }
      }

      @media (max-width: 1140px) {
        gap: 24px;
      }

      @media (max-width: 992px) {
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
      }

      @media (max-width: 768px) {
        width: 100%;
        grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
        gap: 12px;
      }

      @media (max-width: 576px) {
        grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));
      }

      &-item {
        width: 80px;
        height: 80px;
        color: $color--cream;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // background-color: $color--bottle-green;
        border-radius: 12px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        overflow: hidden;

        @media (max-width: 768px) {
          width: 80px;
          height: 80px;
          border-radius: 8px;
        }

        @media (max-width: 576px) {
          width: 64px;
          height: 64px;
          border-radius: 8px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          scale: 1;
        }
      }
    }
  }
}

.section__bg + .section__bg {
  margin-top: 0px !important;
}

.section__footer {
  background-color: $color--bottle-green;
  padding: 60px 0;

  &--slp {
    margin-bottom: 88px;
    @media (max-width: 992px) {
      margin-bottom: 115px;
    }
  }

  &--membership {
    margin-bottom: 84px;
  }

  @media (max-width: 992px) {
    padding: 60px 0;
    margin-top: 40px;
  }

  @media (max-width: 767px) {
    padding: 60px 0;
    padding-bottom: 20px;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 52px;

    @media (max-width: 992px) {
      gap: 40px;
    }

    @media (max-width: 767px) {
      margin-bottom: 0px;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column-reverse;
      gap: 40px;
    }

    &-left {
      display: flex;
      gap: 33px;
      flex-direction: column;

      @media (max-width: 767px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      &-logo {
        height: 48px;
        width: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      gap: 11px;
      max-width: 480px;
      color: $color--white;

      h6 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 24px */
        margin: 0;
      }

      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        opacity: 0.6;
      }
    }

    &-form {
      margin-top: 21px;
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 8px;

      @media (max-width: 576px) {
        grid-template-columns: 1fr;
        gap: 12px;
      }

      &-input {
        border: 1px solid #818490;
        padding: 12px;
        width: 100%;
        font-family: inherit;
        height: 42px;
        background-color: transparent;
        color: $color--white;
        border-radius: 8px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */

        &::placeholder {
          color: #818490;
        }

        &:focus {
          outline: none;
          border-color: $color--white;
        }

        @media (max-width: 576px) {
          height: 52px;
          border-radius: 12px;
        }
      }

      &-button {
        height: 42px;
        border-radius: 8px;
        display: grid;
        place-content: center;
        background-color: $color--mint;
        color: $color--bottle-green;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 16.8px */
        border: none;
        cursor: pointer;
        font-family: inherit;

        @media (max-width: 576px) {
          height: 52px;
          border-radius: 12px;
        }
      }
    }

    &-socials {
      display: flex;
      gap: 16px;
      align-items: center;

      &-link {
        width: 42px;
        height: 42px;
        border-radius: 8px;
        background-color: #002e34;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.4s;

        &:hover {
          background-color: $color--white;

          img {
            opacity: 1;
            filter: contrast(0);
          }
        }

        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
          opacity: 0.5;
        }
      }
    }
  }

  &-divider {
    width: 100%;
    height: 1px;
    background-color: $color--white;
    opacity: 0.2;
  }

  &-lists {
    column-count: 5;
    column-gap: 63px;

    @media (max-width: 1200px) {
      column-count: 4;
      column-gap: 60px;
    }

    @media (max-width: 992px) {
      column-count: 3;
      column-gap: 40px;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: $color--white;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      gap: 12px;
      margin-bottom: 32px;
    }

    h6 {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 20.8px */
      margin: 0;
    }

    &:nth-last-child(-n + 3) {
      gap: 20px;

      ul {
        gap: 16px;
        margin-bottom: 0;
      }
    }

    &:last-child {
      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }

    &:nth-last-child(4) {
      @media (max-width: 768px) {
        margin-bottom: 40px;
      }
    }
    &:nth-last-child(3) {
      @media (max-width: 768px) {
        padding-top: 40px;
        border-top: 1px solid #ffffff33;
      }
    }

    &:not(:nth-last-child(-n + 3)) {
      ul {
        @media (max-width: 768px) {
          display: block;
          margin: 0;
        }

        a {
          @media (max-width: 768px) {
            display: inline;
          }
        }
      }

      li {
        @media (max-width: 768px) {
          display: inline;
          padding-right: 8px;

          &::before {
            content: "";
            width: 3px;
            height: 3px;
            background-color: $color--white;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
            opacity: 0.6;
          }

          &:first-child::before {
            display: none;
          }
        }
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
      list-style: none;
      margin: 0;
      padding: 0;
      // margin-bottom: 20px;

      a {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        opacity: 0.6;
        color: $color--white;
        text-decoration: none;
        transition: all 0.4s;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  &-free-courses {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-heading {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      color: $color--white;
    }

    &-content {
      &-group {
        display: inline;
        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 140%;
          color: $color--white;
          padding-right: 8px;
          white-space: nowrap;

          & + a::before {
            display: none;
          }
        }

        a {
          opacity: 0.6;
          text-decoration: none;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%;
          color: $color--white !important;
          padding-right: 8px;

          position: relative;
          list-style: circle;
          transition: all 0.4s;

          &:hover {
            opacity: 0.8;
          }

          &::before {
            content: "";
            width: 3px;
            height: 3px;
            background-color: $color--white;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            margin-right: 6px;
            // Adjustments for flex container
            flex-shrink: 0; // Prevent the dot from shrinking
          }
        }
      }
    }
  }

  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 14px;
      align-items: flex-start;
      margin-top: -16px;
    }

    p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      color: $color--white;
    }

    &-left {
      display: flex;
      gap: 8px;
      align-items: center;

      a {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        color: $color--white !important;
        text-decoration: none;
        opacity: 0.6;

        transition: all 0.4s;

        &:hover {
          opacity: 0.8;
        }

        @media (max-width: 768px) {
          // display: none;
        }

        &::before {
          content: "";
          width: 3px;
          height: 3px;
          background-color: $color--white;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          margin-right: 6px;
          // Adjustments for flex container
          flex-shrink: 0; // Prevent the dot from shrinking
        }
      }

      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        color: $color--white;
        opacity: 0.6;
      }
    }

    &-right {
      display: flex;
      gap: 20px;
      align-items: center;
      flex-wrap: wrap;

      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        color: $color--white;
        opacity: 0.6;

        display: flex;
        gap: 8px;
        align-items: center;
      }

      button {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        color: $color--white;
        opacity: 0.6;

        transition: all 0.4s;

        &:hover {
          opacity: 0.8;
        }

        display: flex;
        gap: 7px;
        align-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-family: inherit;
        font-feature-settings: unset;
        @media (max-width: 992px) {
          padding: 0;
          text-align: left;
        }
      }
    }

    &-links {
      display: flex;
      gap: 24px;

      a {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        color: $color--white;
        text-decoration: none;
      }
    }
  }
}

.section__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  padding: 0 24px;
  @media (max-width: 992px) {
    align-items: end;
    padding: 0;
  }
  &.small {
    .section__modal-container {
      max-width: 562px;
      @media (max-width: 992px) {
        max-width: 100%;
        border-radius: 16px 16px 0 0;
      }
    }
  }

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }

  &-container {
    background-color: $color--white;
    border-radius: 16px;
    width: 100%;
    height: auto;
    max-height: 90%;
    max-width: 935px;
    position: relative;
    z-index: 1001;
    overflow-y: auto;
    @media (max-width: 992px) {
      border-radius: 16px 16px 0 0;
    }
  }

  &-header {
    padding: 40px;
    padding-bottom: 0px;
    position: sticky;
    top: 0px;
    background-color: $color--white;
    z-index: 10;
    border-radius: 16px;

    &-text {
      display: flex;
      flex-direction: column;
      gap: 6px;

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 19.2px */
      }
    }

    &-container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      align-items: center;
      border-bottom: 1px solid #b3b5bc;

      &:has(.section__modal-header-subheading) {
        align-items: flex-start;

        .section__modal-close {
          margin-top: 8px;
        }
      }
    }

    @media (max-width: 576px) {
      padding: 20px;
      padding-bottom: 0;
    }

    h2 {
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 31.2px */
      letter-spacing: -0.12px;

      @media (max-width: 576px) {
        font-size: 20px;
      }
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: grid;
      place-items: center;
    }
  }

  &-content {
    padding: 40px;
    padding-top: 24px;

    @media (max-width: 576px) {
      padding: 20px;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-group {
      display: flex;
      flex-direction: column;
      gap: 8px;

      label {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 16.8px */
        margin-bottom: 8px;

        span {
          color: #db0000;
        }
      }

      &-error {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        color: #db0000;
      }

      label {
        &:has(+ .section__modal-form-group-error) {
          div {
            border-color: #db0000 !important;
          }
        }
      }

      input {
        display: flex;
        height: 52px;
        padding: 16px 24px 16px 12px;
        flex-direction: column;
        align-items: flex-start;
        font-family: inherit;
        gap: 12px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid #002726;

        &:has(+ .section__modal-form-group-error) {
          border-color: #db0000 !important;
        }

        &::placeholder {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 16.8px */
          color: #9a9da6;
        }

        &:focus {
          outline: none;
          border-color: #002726;
        }
      }
    }

    &-buttons {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 26px;
      align-items: center;
      justify-content: center;
    }

    &-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 17px 0px 16px 0px;
      background-color: #002726;
      color: #fff;
      border-radius: 8px;
      font-size: 16px;
      width: 100%;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      height: 52px;
      cursor: pointer;
      border: none;
      transition: all 0.4s;
      font-feature-settings: inherit;
      font-family: inherit;
    }

    &-link {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 16.8px */
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      text-decoration: none;
      color: inherit;

      svg {
        translate: 0 10%;
      }
    }
  }

  &-currency-tabs {
    display: flex;
    gap: 32px;
    overflow: auto;

    @media (max-width: 576px) {
      gap: 16px;
      flex-direction: column;
    }

    button {
      border: 1px solid $color--grey-4;
      padding: 12px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 16.8px */
      font-family: inherit;
      background-color: $color--white;
      cursor: pointer;
      color: $color--bottle-green;
      text-decoration: none;
      text-align: center;
      min-width: 165px;
      white-space: nowrap;
      border: 1px solid transparent;
      transition: all 0.4s;

      &:hover {
        background-color: #f2f3f4;
      }

      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        letter-spacing: 0.504px;
        text-transform: uppercase;
        color: #818490;
      }

      &.active {
        border-color: $color--bottle-green;

        &:hover {
          background-color: $color--white;
        }
      }
    }
  }

  &-time-tabs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 12px;
    justify-content: flex-start;
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 0;
    }

    button {
      border: 1px solid $color--grey-4;
      padding: 12px;
      border-radius: 8px;
      display: flex;
      // flex-direction: column;
      gap: 4px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      font-family: inherit;
      background-color: $color--white;
      cursor: pointer;
      color: $color--bottle-green;
      text-decoration: none;
      text-align: center;
      min-width: 165px;
      white-space: nowrap;
      border: 1px solid transparent;
      transition: all 0.4s;
      width: 100%;
      display: flex;
      align-items: center;

      &:hover {
        background-color: #f2f3f4;
      }

      span {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        letter-spacing: 0.504px;
        text-transform: uppercase;
        color: #818490;
      }

      &.active {
        // border: 1px solid #002726;
        background: #f2f3f4;
        background-color: #f2f3f4;

        &:hover {
          background-color: $color--white;
        }
      }
    }
  }
  &-secondary-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(3, 10, 33, 0.1);

    @media (max-width: 576px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-search {
    position: relative;
    input {
      border-radius: 8px;
      border: 1px solid var(--Greys-Dark-mode-Grey-2, #9a9da6);
      background: var(--Primary-Tide-White, #fff);
      height: 42px;
      width: 258px;
      padding: 10px 20px 11px 13px;
      font-family: inherit;

      &::placeholder {
        color: #9a9da6;
      }

      &:focus {
        outline: none;
        border-color: #002726;
      }

      @media (max-width: 576px) {
        width: 100%;
      }
    }

    @media (max-width: 576px) {
      width: 100%;
    }

    svg {
      position: absolute;
      right: 11px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.section__fixed-card {
  position: fixed;
  bottom: 0;
  width: 100%;
  right: 0;
  padding: 20px 0;
  background: #fffaf1;
  z-index: 99999;
  border-top: 1px solid #9a9da6;
  transition: all 0.4s;
  // transform: translateY(0%);

  &--membership {
    .section__fixed-card-button {
      gap: 12px;

      &--white {
        background: #fff;
      }

      @media (max-width: 767px) {
        width: 161px;
        svg {
          display: none;
        }
      }
    }

    .section__fixed-card-text {
      max-width: unset;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    padding: 12px 0;
    z-index: 9999;
  }

  &--hide {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    // transform: translateY(100%);
  }

  &-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 12px;
      align-items: center;
      justify-content: center;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 272px;

    p {
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 24px */

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &-link {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    text-decoration-line: underline;
    color: $color--bottle-green;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  &-buttons {
    display: flex;
    gap: 23px;
    align-items: center;

    @media (max-width: 768px) {
      gap: 8px;
    }
  }

  &-button {
    position: relative;
    color: $color--bottle-green;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 24px */
    cursor: pointer;
    gap: 10px;
    height: 59px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid rgba(0, 39, 38, 0.4);
    background: var(--Primary-GS-Off-White, #fffaf1);
    &:hover {
      border: 1px solid rgba(0, 39, 38, 0.4);
      background: #fff2db;
    }
    @media (max-width: 768px) {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
    }

    &--mint {
      border-radius: 12px;
      border: 1px solid rgba(0, 39, 38, 0.4);
      background: var(--Primary-GS-Mint-Green, #33c375);

      &:hover {
        border: 1px solid rgba(0, 39, 38, 0.4);
        background: #2caa65;
      }
    }
  }
}

/* Dropdown */

.dropdown {
  display: inline-block;
  position: relative;
}

.dd-button {
  display: inline-block;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 10px 30px 10px 20px;
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
}

.dd-button:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

.dd-button:hover {
  background-color: #eeeeee;
}

.dd-input {
  display: none !important;
}

.dd-menu {
  position: absolute;
  top: 100%;
  border: 1px solid #9a9da6;
  border-radius: 4px;
  padding: 0;
  margin: 2px 0 0 0;
  background-color: #ffffff;
  list-style-type: none;
}

.dd-input + .dd-menu {
  display: none;
}

.dd-input:checked + .dd-menu {
  display: block;
  height: 150px;
  overflow: auto;
}

.dd-menu li {
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;
}

.dd-menu li:hover {
  background-color: #f6f6f6;
}

.dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.dd-menu li.divider {
  padding: 0;
  border-bottom: 1px solid #cccccc;
}

.section__counter {
  display: flex;
  align-items: center;
  gap: 0px;

  &-digit {
    overflow: hidden;
  }

  &-number {
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .breadcrumbs-container.membership {
    margin-top: 0;
  }
  .meet-mentors-group {
    flex-direction: column;
  }
  .accordion-header svg {
    width: 24px;
    height: 24px;
  }
}
.section__influencers-slider-div:hover
  .section__influencers-slider-div-container {
  aspect-ratio: 1 / 1.6;
  -moz-aspect-ratio: 1 / 1.6;
  -webkit-aspect-ratio: 1 / 1.6;
  border-radius: 100px;
  overflow: hidden;
  display: grid;
  align-items: end;
  height: 46.47px;
  width: 37px;
  margin-left: 5.5px;
  margin-top: -48.27px;
}
.breadcrumbs-list {
  display: flex !important;
  gap: 5px;
  align-items: center;
}
.colored-text {
  color: #33c375 !important;
}
.shortcode {
  font-weight: 500 !important;
  color: var(--Primary-GS-Bottle-Green, #002726) !important ;
}
.vid-btn:hover .section__testimonials-video-button {
  display: block !important;
}
.d-hidden {
  display: none;
}
.d-block {
  display: block;
}
.modal-subheading {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.12px;
}

.section__premium-access {
  margin: 80px 0;

  @media (max-width: 767px) {
    margin-bottom: 46px;
  }

  &-container {
    flex-direction: column;
    display: flex;
    gap: 40px;

    @media (max-width: 767px) {
      gap: 52px;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &-cards {
    display: grid;
    grid-template-columns: repeat(3, 342px);
    gap: 51px;
    overflow-x: auto;
    scrollbar-width: none;

    @media (max-width: 767px) {
      grid-template-columns: repeat(3, 270px);
      gap: 20px;
    }

    &-col {
      display: grid;
      grid-template-columns: 1fr;
      gap: 32px;

      @media (max-width: 767px) {
        gap: 16px;
      }
    }
  }

  &-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 17px;
    border-radius: 16px;
    border: 1px solid rgba(151, 167, 166, 0.4);
    position: relative;
    overflow: hidden;
    background-color: #fffaf1;

    @media (max-width: 767px) {
      padding: 16px;
    }

    &-texture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.2;
      object-fit: cover;
      z-index: 999;
      pointer-events: none;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 8px;

      h4 {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 31.2px */
        letter-spacing: -0.12px;
        color: inherit;
        display: flex;
        align-items: center;

        @media (max-width: 767px) {
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 24px */
        }
      }

      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
        opacity: 0.7;
        color: inherit;
      }
    }

    &-thumb {
      height: 173px;
      width: 308px;
      border-radius: 12px;
      overflow: hidden;
      position: relative;
      pointer-events: none;

      @media (max-width: 767px) {
        width: 236px;
        height: 146px;
      }

      &-img {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-lottie {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 12px;
        overflow: hidden;

        &-el {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &-price {
      margin-top: 22px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      @media (max-width: 767px) {
        margin-top: 24px;
      }

      p {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 14.4px */
        color: inherit;
      }

      h5 {
        color: inherit;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 31.2px */
        letter-spacing: -0.12px;

        @media (max-width: 767px) {
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 24px */
        }
      }
    }

    &--primary {
      &--1 {
        background-color: #f7e5ff;
        color: #332b66;

        .section__premium-access-card-thumb {
          background-color: #332b66;
        }
      }

      &--2 {
        background-color: #e1f9ff;
        color: #004152;
        grid-row: 2;

        .section__premium-access-card-thumb {
          background-color: #004152;
        }
      }

      &--3 {
        background-color: #ffebe3;
        color: #533c00;
        grid-row: 3;

        .section__premium-access-card-thumb {
          background-color: #601c01;
        }
      }
    }
  }
}

.section__self-placed {
  padding: 80px 0;
  background-color: #fffaf1;

  @media (max-width: 992px) {
    padding-top: 40px;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 992px) {
      gap: 28px;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;

    h2 {
      max-width: 625px;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;
      margin-bottom: 12px !important;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
    }
  }

  &-arrows {
    display: flex;
    align-items: center;
    gap: 12px;

    @media (max-width: 992px) {
      display: none;
    }
  }

  &-arrow {
    height: 38px;
    width: 38px;
    border-radius: 100px;
    border: 1px solid $color--bottle-green;
    display: grid;
    place-items: center;
    background-color: transparent;
    cursor: pointer;
    stroke: $color--bottle-green;

    &:hover {
      background-color: $color--bottle-green;
      stroke: $color--white;
    }

    &-prev {
      svg {
        transform: rotate(180deg);
      }
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .swiper-slide {
    width: fit-content !important;
  }

  &-group {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    margin-top: 28px;
    overflow: auto;
    padding-left: calc((100vw - 1150px) / 2) !important;

    @media (max-width: 1280px) {
      padding-left: calc((100vw - 1092px) / 2) !important;
    }

    @media (max-width: 1140px) {
      padding-left: 24px !important;
    }

    @media (max-width: 992px) {
      gap: 16px;
      margin-top: 44px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    button {
      border: 1px solid $color--grey-4;
      padding: 12px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      font-family: inherit;
      background-color: $color--white;
      cursor: pointer;
      color: $color--bottle-green;
      text-decoration: none;
      text-align: center;
      white-space: nowrap;
      transition: all 0.4s;
      // &:hover {
      //   background-color: #fffaf1 !important;
      // }
      &.active {
        border: 1px solid $color--bottle-green;
        background-color: $color--bottle-green;
        color: $color--white;
        &:hover {
          background-color: $color--bottle-green !important;
        }
      }
    }
  }

  &-slider {
    margin-top: 40px;

    @media (max-width: 992px) {
      margin-top: 44px;
    }

    &-header {
      display: flex;
      flex-direction: column;
      gap: 12px;

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 25.6px */
      }
    }

    &-swiper {
      padding-left: calc((100vw - 1150px) / 2) !important;

      @media (max-width: 1280px) {
        padding-left: calc((100vw - 1092px) / 2) !important;
      }

      @media (max-width: 1140px) {
        padding-left: 24px !important;
      }

      @media (max-width: 576px) {
        display: none;
      }

      &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding: 0 20px;
        height: 59px;
        border-radius: 12px;
        border: 1px solid $color--bottle-green;
        background-color: white;
        color: $color--bottle-green;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        cursor: pointer;
        font-family: inherit;
        text-decoration: none;
        width: 100%;
      }
    }

    &-cards {
      display: none;
      flex-direction: column;
      gap: 24px;
      padding: 0px 24px;

      @media (max-width: 576px) {
        display: flex;
      }
    }

    &-slide {
      width: 315px;
      height: 307px;
      position: relative;
      border-radius: 16px;
      overflow: hidden;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border-radius: 20px;
      background-color: #001a19;
      color: white;

      @media (max-width: 576px) {
        width: 100%;
      }

      &-texture {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.2;
        object-fit: cover;
        z-index: 999;
        pointer-events: none;
      }

      &-header {
        display: flex;
        flex-direction: column;
        gap: 16px;

        h3 {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 31.2px */
          letter-spacing: -0.12px;
          color: inherit;
        }
      }

      &-list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &-item {
          display: flex;
          align-items: flex-start;
          gap: 8px;

          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
          }

          svg {
            min-height: 13px;
            min-width: 13px;
          }
        }
      }

      &-footer {
        margin-top: auto;

        span {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */

          @media (max-width: 576px) {
            font-size: 14px;
          }
        }

        p {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 31.2px */
          letter-spacing: -0.12px;
        }
      }
    }
  }
}

.section__masterclass {
  padding: 80px 0 50px 0;

  @media (max-width: 992px) {
    padding-top: 40px;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 767px) {
      gap: 32px;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .subheading__secondary {
      margin-top: 4px !important;
    }
  }

  &-cards {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 36px;

    @media (max-width: 992px) {
      flex-direction: column;
      display: flex;
      gap: 32px;
    }
  }

  &-card {
    background-color: #fffaf1;
    border: 1px solid rgba(151, 167, 166, 0.4);
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    &-texture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 999;
      mix-blend-mode: soft-light;
      pointer-events: none;
    }

    &-header {
      display: flex;
      flex-direction: column;
      gap: 12px;

      h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 24px */
      }

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 19.2px */
        @media (max-width: 992px) {
          font-size: 14px;
        }
      }
    }

    &--1 {
      grid-column: 1 / -1;
      display: flex;
      flex-direction: column;
      gap: 50px;

      @media (max-width: 992px) {
        gap: 32px;
      }

      .section__masterclass-card-header {
        padding: 32px 24px;
        padding-bottom: 0;
      }
    }

    &--2 {
      padding: 32px 24px;
      grid-column: span 3;
    }

    &--3 {
      padding: 32px 24px;
      grid-column: span 2;

      @media (max-width: 992px) {
        height: 329px;
      }
    }

    &-img {
      width: 100%;
      height: 100%;
      margin-top: auto;
      display: flex;
      align-items: flex-end;
      padding-right: 52px;

      @media (max-width: 992px) {
        padding-right: 36px;
      }

      @media (max-width: 767px) {
        padding-right: 24px;
      }

      @media (max-width: 576px) {
        padding-right: 18px;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  &-marquee {
    position: relative;
    z-index: 3;
    margin-top: 28px;

    @keyframes marqueeMasterclass {
      to {
        transform: translateX(calc(-50% - 9px));
      }
    }

    &-slider {
      display: flex;
      align-items: center;
      gap: 18px;
      width: max-content;
      animation: marquee linear infinite;

      @media (max-width: 992px) {
        gap: 16px;
      }
    }

    &-card {
      width: 138px;
      height: 225.75px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      border: 0.75px solid #97a7a6;
      background: #ffffff;
      padding: 12px;
      border-radius: 12px;

      @media (max-width: 767px) {
        width: 156px;
        height: 273px;
        border-radius: 16px;
      }

      &-img {
        background-color: $color--bottle-green;
        height: 114px;
        width: 114px;
        border-radius: 9px;
        overflow: hidden;

        @media (max-width: 767px) {
          width: 132px;
          height: 132px;
          border-radius: 12px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-text {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      h4 {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 14.4px */
      }

      p {
        font-size: 10.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; /* 14.7px */
      }

      &-brand {
        margin-top: auto;
        height: 14.25px;
        width: auto;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  &-stack {
    position: relative;
    transform: translateY(60px);
    width: 100%;
    height: 100%;

    @media (max-width: 992px) {
      height: 329px;
    }

    &-card {
      height: 240px;
      width: 100%;
      background-color: white;
      border-radius: 12px;
      overflow: hidden;
      position: absolute;
      border: 3px solid rgba(#000000, 0.4);

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.section__workshop {
  padding: 80px 0;

  @media (max-width: 992px) {
    padding: 40px 0 0 0;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 92px;
    border-radius: 20px;
    background-color: #001a19;
    overflow: hidden;
    padding: 60px 0;
    position: relative;

    @media (max-width: 992px) {
      gap: 40px;
      border-radius: 0;
    }

    @media (max-width: 767px) {
      gap: 40px 24px;
    }
  }

  &-texture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 9999;
    opacity: 0.2;
    pointer-events: none;
    scale: 1.02;
  }

  &-gradient {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
    pointer-events: none;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-inline: 60px;
    position: relative;
    z-index: 99;

    h2,
    p {
      color: white;
    }

    @media (max-width: 992px) {
      padding-inline: 24px;
    }
  }

  --gapMarqueeHalf: 12px;

  @media (max-width: 767px) {
    --gapMarqueeHalf: 9px;
  }

  &-marquees {
    display: flex;
    flex-direction: column;
    gap: calc(var(--gapMarqueeHalf) * 2);
    z-index: 9999;

    @media (max-width: 992px) {
      gap: 18px;
    }
  }

  &-marquee {
    position: relative;
    z-index: 3;

    &--reverse {
      .section__workshop-marquee-slider {
        animation: marqueeReverseWorkshop 32s linear infinite;
      }
    }

    @keyframes marqueeReverseWorkshop {
      from {
        transform: translateX(calc(-100% + 1140px + 0px));
      }
      to {
        transform: translateX(calc(-50% + 1140px - var(--gapMarqueeHalf)));
      }
    }

    @keyframes marqueeWorkshop {
      to {
        transform: translateX(calc(-50% - var(--gapMarqueeHalf)));
      }
    }

    &-slider {
      display: flex;
      align-items: center;
      gap: 18px;
      width: max-content;
      animation: marqueeWorkshop 32s linear infinite;

      // &:hover {
      //   animation-play-state: paused;
      // }

      @media (max-width: 992px) {
        gap: 16px;
      }
    }

    &-card {
      border: 1px solid $color--bottle-green;
      padding: 16px;
      border-radius: 16px;
      width: 255px;
      transition: all 0.4s;
      background-color: #fff;
      aspect-ratio: 1/1;

      @media (max-width: 767px) {
        width: 200px;
        padding: 12px;
        border-radius: 12px;
      }

      // &:hover {
      //   background-color: #fffaf1;
      // }

      &-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }

      &-header {
        display: flex;
        flex-direction: column;
        gap: 12px;

        @media (max-width: 767px) {
          gap: 10px;
        }

        h3 {
          font-size: 19.456px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 25.293px */
          letter-spacing: -0.097px;

          @media (max-width: 767px) {
            font-size: 15.319px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%; /* 19.915px */
            letter-spacing: -0.077px;
          }
        }
      }

      &-stats {
        display: flex;
        align-items: center;
        gap: 20px;
        transition: all 0.4s;

        &-item {
          display: flex;
          align-items: center;
          gap: 6px;

          p {
            font-size: 11.349px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 13.619px */

            @media (max-width: 767px) {
              font-size: 8.936px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 10.723px */
            }
          }

          &.section__workshop-marquee-card-stats-price {
            p {
              font-size: 16.213px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%; /* 19.456px */

              @media (max-width: 767px) {
                font-size: 12.736px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%; /* 15.283px */
              }
            }
          }

          &-icon {
            width: 12px;
            height: 12px;
            display: grid;
            place-items: center;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      &-author {
        display: flex;
        align-items: center;
        gap: 8px;

        &-img {
          width: 32px;
          height: 32px;
          min-width: 32px;
          border-radius: 50%;
          overflow: hidden;
          border: 1px solid $color--grey-1;
          display: grid;
          place-items: center;
          background-color: $color--bottle-green;
          position: relative;

          @media (max-width: 992px) {
            width: 24px;
            height: 24px;
            min-width: 24px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &-text {
          display: flex;
          flex-direction: column;
          gap: 4px;
          text-transform: capitalize;

          p {
            font-size: 11.349px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%; /* 13.619px */

            @media (max-width: 992px) {
              font-size: 8.936px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%; /* 10.723px */
            }
          }

          span {
            font-size: 11.349px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%; /* 13.619px */
            display: flex;

            @media (max-width: 992px) {
              font-size: 8.936px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%; /* 10.723px */
            }
          }
        }
      }
    }
  }
}

.section__earn-certificate {
  padding-bottom: 80px;

  @media (max-width: 992px) {
    padding: 0px 0px;
  }

  &-container {
    border: 1px solid #9a9da6;
    border-radius: 20px;
    background-color: #fffaf1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    height: 353px;
    position: relative;
    padding: 0;

    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
      height: 524px;
      border-radius: 0;
      border: none;
      mask-image: linear-gradient(to bottom, #000000 70%, transparent 100%);
    }
  }

  &-texture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 999;
    pointer-events: none;
    mix-blend-mode: soft-light;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 60px;

    .subheading__secondary {
      margin-top: 4px !important;
      font-weight: 400;
      line-height: 25.6px;
    }

    @media (max-width: 992px) {
      padding: 60px 24px 30px;
    }
  }

  &-img {
    position: relative;

    img {
      position: absolute;
      top: 60px;
      left: auto;
      right: 0;

      @media (max-width: 992px) {
        position: unset;
        transform: translateY(0%) translateX(60px);
        width: 100%;
        height: auto;
      }

      @media (max-width: 767px) {
        transform: translateY(0%) translateX(24px);
      }
    }
  }
}

.section__tools {
  padding: 40px 0;

  @media (max-width: 992px) {
    padding: 60px 0;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 992px) {
      gap: 28px;
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    width: 100%;

    h2 {
      max-width: 625px;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 19.2px */
      text-transform: uppercase;
      margin-bottom: 12px !important;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
    }
  }

  &-arrows {
    display: flex;
    align-items: center;
    gap: 12px;

    @media (max-width: 992px) {
      display: none;
    }
  }

  &-arrow {
    height: 38px;
    width: 38px;
    border-radius: 100px;
    border: 1px solid $color--bottle-green;
    display: grid;
    place-items: center;
    background-color: transparent;
    cursor: pointer;
    stroke: $color--bottle-green;

    &:hover {
      background-color: $color--bottle-green;
      stroke: $color--white;
    }

    &-prev {
      svg {
        transform: rotate(180deg);
      }
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .swiper-slide {
    width: fit-content !important;
  }

  &-group {
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    margin-top: 28px;
    overflow: auto;
    padding-left: calc((100vw - 1150px) / 2) !important;

    @media (max-width: 1280px) {
      padding-left: calc((100vw - 1092px) / 2) !important;
    }

    @media (max-width: 1140px) {
      padding-left: 24px !important;
    }

    @media (max-width: 992px) {
      gap: 16px;
      margin-top: 44px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    button {
      border: 1px solid $color--grey-4;
      padding: 12px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 16.8px */
      font-family: inherit;
      background-color: $color--white;
      cursor: pointer;
      color: $color--bottle-green;
      text-decoration: none;
      text-align: center;
      white-space: nowrap;
      transition: all 0.4s;
      &:hover {
        background-color: #fffaf1 !important;
      }
      &.active {
        border: 1px solid $color--bottle-green;
        background-color: $color--bottle-green;
        color: $color--white;
        &:hover {
          background-color: $color--bottle-green !important;
        }
      }
    }
  }

  &-slider {
    margin-top: 40px;

    @media (max-width: 992px) {
      margin-top: 28px;
    }

    &-header {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &-swiper {
      padding-left: calc((100vw - 1150px) / 2) !important;

      @media (max-width: 1280px) {
        padding-left: calc((100vw - 1092px) / 2) !important;
      }

      @media (max-width: 1140px) {
        padding-left: 24px !important;
      }
    }

    &-slide {
      width: 237px;
      min-height: 468px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 16px;
      border: 1px solid rgba(151, 167, 166, 0.4);

      &-header {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: 1;
        padding: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(151, 167, 166, 0.4);

        h3 {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 31.2px */
          letter-spacing: -0.12px;
        }

        p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 22.4px */
          opacity: 0.7;
        }

        &-img {
          border: 1px solid rgba(151, 167, 166, 0.4);
          background-color: #fffaf1;
          display: flex;
          height: 198px;
          width: 100%;
          border-radius: 16px;
          align-items: center;
          justify-content: center;
          margin-bottom: 8px;
          overflow: hidden;

          img {
            width: auto;
            height: 120px;
            object-fit: contain;
            border-radius: 16px;
          }
        }
      }

      &-footer {
        padding: 20px;
        padding-top: 15px;
        display: flex;
        flex-direction: column;

        span {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
        }

        p {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 31.2px */
          letter-spacing: -0.12px;

          &.section__tools-slider-slide-footer-desc {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 19.6px */
            margin-bottom: 16px !important;
          }
        }
      }
    }
  }
}

.section__community {
  padding: 60px 0;

  &-container {
    display: flex;
    flex-direction: column;
    gap: 60px;

    @media (max-width: 992px) {
      gap: 40px;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 725px;

    .subheading__secondary {
      margin-top: 4px !important;
      font-weight: 400;
      line-height: 25.6px;
    }
  }

  &-img {
    width: 100%;
    height: auto;
    mask-image: linear-gradient(to bottom, #000000 50%, transparent 100%);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.section__expert-led {
  background-color: #fffaf1;
  padding: 80px 0;

  @media (max-width: 992px) {
    padding: 60px 0;
    margin-top: 0px;
  }

  @media (max-width: 576px) {
    padding: 40px 0 40px 0;
  }

  &-header {
    display: grid;
    grid-template-columns: 1fr auto;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      gap: 24px;
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 40%;
      @media (max-width: 992px) {
        width: 100%;
      }
      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
        text-transform: uppercase;

        @media (max-width: 992px) {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 160%; /* 22.4px */
        }
      }

      h2 {
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 46.8px */
        letter-spacing: -0.558px;

        @media (max-width: 992px) {
          font-size: 28px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%; /* 36.4px */
          letter-spacing: -0.14px;
        }
      }
    }

    &-desc {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 25.6px */
      margin-top: 16px !important;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 40px;

    &-text {
      h5 {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */
      }
    }
  }

  &-content-grid {
    display: grid;
    row-gap: 40px;
    column-gap: 197px;

    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

    @media (max-width: 1140px) {
      column-gap: 100px;
    }

    @media (max-width: 992px) {
      gap: 24px;
      grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    }

    @media (max-width: 576px) {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }

    &-item {
      display: flex;
      align-items: flex-start;
      gap: 24px;

      @media (max-width: 992px) {
        gap: 16px;
      }

      &-text {
        display: flex;
        flex-direction: column;
        gap: 6px;

        h6 {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 19.2px */
          margin: 0;
        }

        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 25.6px */
          margin: 0;
        }
      }

      &-img {
        width: 72px;
        min-width: 72px;
        height: 72px;
        border-radius: 12px;
        background-color: rgba(3, 10, 33, 0.05);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        @media (max-width: 992px) {
          width: 52px;
          height: 52px;
          min-width: 52px;
        }

        img {
          width: 44px;
          height: 44px;
          object-fit: contain;

          @media (max-width: 992px) {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
}

.section__career {
  padding-bottom: 120px;

  @media (max-width: 992px) {
    padding-bottom: 60px;
  }

  &-container {
    border: 1px solid #9a9da6;
    border-radius: 20px;
    background-color: #fffaf1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0;
    gap: 40px;
    position: relative;

    @media (max-width: 992px) {
      gap: 0;
      border-radius: 0;
      border: none;
      background-color: white;
    }
  }

  &-texture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 999;
    pointer-events: none;
    mix-blend-mode: soft-light;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 32px;
    height: auto;

    .subheading__secondary {
      margin-top: 4px !important;
      font-weight: 400;
    }
  }

  &-img {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    padding-right: 32px;
    align-items: flex-end;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    span {
      position: absolute;
      bottom: 58px;
      left: 50%;
      translate: -50%;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 31.2px */
      letter-spacing: -0.12px;
      z-index: 1;

      @media (max-width: 992px) {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 36.4px */
        letter-spacing: -0.14px;
        bottom: 29px;
        margin-right: -32px;
      }
    }
  }
}

.section__pro {
  background-color: #001a19;
  padding: 82px 0;
  position: relative;

  @media (max-width: 992px) {
    padding: 60px 0;
  }

  &-container {
    display: grid;
    grid-template-columns: 1fr 2fr;

    h2,
    p,
    h5 {
      color: white;
    }

    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
      gap: 46px;
    }
  }

  &-texture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #001a19;
    z-index: 9999;
    opacity: 0.1;
    pointer-events: none;
  }

  &-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9;
    pointer-events: none;

    @media (max-width: 992px) {
      bottom: unset;
      top: 0;
      left: unset;
      right: 0;
      rotate: 180deg;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 360px;
  }

  &-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    max-width: 611px;
    margin-left: auto;

    @media (max-width: 992px) {
      gap: 16px;
      margin: 0;
      max-width: unset;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-text {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 19.2px */

        @media (max-width: 992px) {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 160%; /* 22.4px */
          max-width: 160px;
        }
      }

      &-price {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 19.2px */

        &-total {
          text-decoration: line-through;
        }

        &-main {
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%; /* 24px */
        }
      }

      &-total {
        margin-top: 10px;
      }
    }

    &-divider {
      width: 100%;
      height: 1px;
      background-color: #b3b5bc4d;
    }
  }
}

.section__plans {
  background: #fffaf1;
  padding: 80px 0 149px 0;

  @media (max-width: 992px) {
    padding: 60px 0 60px 0;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 360px;
  }

  &-table {
    &-select {
      justify-self: flex-start;

      @media (max-width: 992px) {
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        .membership.dropdown {
          width: auto;
        }
      }

      &.section__plans-table-select--mobile {
        display: none;

        @media (max-width: 992px) {
          display: block;
          pointer-events: all;
          visibility: visible;
          opacity: 1;
          margin-top: 22px;
          .membership.dropdown {
            width: 242px;
          }
        }
      }
    }
    &-row {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      border-bottom: 1px solid rgba(3, 10, 33, 0.1);
      height: 69px;
      align-items: center;

      @media (max-width: 992px) {
        height: 88px;
        svg {
          scale: 0.6;
        }
      }

      &-last {
        border-bottom: none;
        justify-items: center;
        height: auto;
        padding-top: 29px;

        p {
          max-width: 85px;
          @media (max-width: 992px) {
            max-width: 75px;
          }
        }
      }
    }
    &-head {
      padding: 0;
      padding-bottom: 50px;
      justify-items: center;
      height: auto;

      @media (max-width: 992px) {
        padding-bottom: 20px;
      }

      p {
        max-width: 100px;
      }
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 20.8px */
      text-align: center;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 18.2px */
      }

      @media (max-width: 992px) {
        max-width: 85px;
        justify-self: center;
      }

      &.text--left {
        text-align: left !important;
        justify-self: flex-start;
      }

      &.text--center {
        justify-self: center;
      }
    }
  }
}

.section__plans-spc {
  background: #fffaf1;
  padding: 80px 0 149px 0;

  @media (max-width: 992px) {
    padding: 60px 0 60px 0;
  }

  &-container {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 360px;
  }

  &-table {
    &-select {
      justify-self: flex-start;

      @media (max-width: 992px) {
        pointer-events: none;
        visibility: hidden;
        opacity: 0;
        .membership.dropdown {
          width: auto;
        }
      }

      &.section__plans-spc-table-select--mobile {
        display: none;

        @media (max-width: 992px) {
          display: block;
          pointer-events: all;
          visibility: visible;
          opacity: 1;
          margin-top: 22px;
          .membership.dropdown {
            width: 242px;
          }
        }
      }
    }
    &-row {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr;
      border-bottom: 1px solid rgba(3, 10, 33, 0.1);
      height: 69px;
      align-items: center;

      @media (max-width: 992px) {
        grid-template-columns: 1fr 1fr 1fr;
        height: 88px;
        svg {
          scale: 0.6;
        }
      }

      &-last {
        border-bottom: none;
        justify-items: center;
        height: auto;
        padding-top: 29px;

        p {
          max-width: 85px;
          @media (max-width: 992px) {
            max-width: 75px;
          }
        }

        button {
          display: flex;
          padding: 13px 16px 12px 16px;
          justify-content: center;
          align-items: center;
          align-self: stretch;
          border-radius: 8px;
          border: 1px solid rgba(0, 39, 38, 0.40);
          background: #33C375;
        }
      }
    }
    &-head {
      padding: 0;
      padding-bottom: 50px;
      justify-items: center;
      height: auto;

      @media (max-width: 992px) {
        padding-bottom: 20px;
      }

      p {
        max-width: 100px;
      }
    }
    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 20.8px */
      text-align: center;

      @media (max-width: 992px) {
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 18.2px */
      }

      @media (max-width: 992px) {
        max-width: 85px;
        justify-self: center;
      }

      &.text--left {
        text-align: left !important;
        justify-self: flex-start;
      }

      &.text--center {
        justify-self: center;
      }
    }
  }
}

.section__faq {
  padding: 80px 0;

  @media (max-width: 992px) {
    padding: 40px 0 40px 0;
  }

  &-container {
    display: grid;
    grid-template-columns: 1fr 2fr;

    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 360px;
  }
  .accordion {
    max-width: 666px;
    width: 100%;
    margin-left: auto;

    svg {
      min-height: 24px;
      min-width: 24px;
    }

    .accordion-header {
      gap: 12px;
    }
  }
}
// .not-found-wrap {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 40px;
//   background-color: #001a19;
//   min-height: 718px;
//   border-radius: 10px;
// }
// .not-found-wrap .grid {
//   width: 100%;
//   height: 718px;
// }
// .not-found-wrap .grid img {
//   width: 100%;
//   height: 100%;
//   z-index: 1;
//   // position: relative;
// }
// .not-found-wrap .grid img.texture {
//   z-index: 2;
// }
// .not-found-content {
//   position: absolute;
//   max-width: 830px;
//   min-height: 297px;
//   // background-color: #004152;
// }
// .not-found-content img {
//   width: 100%;
//   height: 100%;
// }
/* Dropdown */

.membership.dropdown {
  display: inline-block;
  position: relative;
  width: 242px;
  
  .dd-buttons {
    display: inline-block;
    height: 52px;
    padding: 16px 24px 16px 12px;
    flex-direction: column;
    align-items: flex-start;
    font-family: inherit;
    gap: 12px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid rgba(0, 39, 38, 0.4);
    cursor: pointer;
    width: 100%;
    font-size: 16px;
    line-height: 20.8px;
    font-weight: 500;
    color: #002726;
    transition: all 0.3s;
    background-color: white;
  
    &:hover {
      background-color: white;
    }
  }
}


.industry-text-selected {
  color: black;
}

.membership.dropdown .dd-buttons:after {
  content: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.33325 6.33398L5.99992 1.66732L10.6666 6.33398' stroke='%23002726' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  position: absolute;
  top: 55%;
  right: 15px;
  transform: translateY(-55%) rotate(180deg);
  transition: transform 0.3s;
}

.membership.dropdown .dd-buttons:has(+ .dd-input:checked)::after {
  transform: translateY(-81%) rotate(0deg);
}

.membership.dropdown .dd-buttons:has(+ .dd-input:checked) {
  border-radius: 12px;
}

.membership.dropdown .dd-input {
  display: none;
}

.membership.dropdown .dd-menu {
  top: calc(100% + 5px);
  border-radius: 12px;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
  list-style-type: none;
  width: 100%;
  z-index: 9;
}

.membership.dropdown .dd-input + .dd-menu {
  display: none;
  box-shadow: 11px 55px 33px 0px rgba(0, 0, 0, 0.05),
    1px 6px 14px 0px rgba(0, 0, 0, 0.1);
  scrollbar-width: none;
}

.membership.dropdown .dd-input:checked + .dd-menu {
  display: block;
  padding: 12px;
  // height: 212px;
  height: auto;
  width: 242px;
}

.membership.dropdown:has(.dd-input:checked) .dd-buttons {
  background-color: #ffffff;
}

.membership.dropdown .dd-menu li {
  padding: 10px 12px;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: none;
  font-size: 16px;
  line-height: 20.8px;
  font-weight: 500;
  margin-bottom: 8px;
  transition: all 0.3s;
  position: relative;

  svg {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.membership.dropdown .dd-menu li:hover {
  background-color: rgba(3, 10, 33, 0.05);
  border-radius: 4px;
}

.membership.dropdown .dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.membership.dropdown .dd-menu li.divider {
  padding: 0;
  // border-bottom: 1px solid #cccccc;
}
.section__tabs-grid.membership {
  grid-template-rows: repeat(3, 187px);
}
// gs-2075 somewhere here make capital
.section__tabs-grid-item-special-text.membership p {
  font-size: 20px;
  line-height: 24px;
}
.section__tabs-grid-item-special-text.membership span {
  max-width: 100%;
  text-transform: initial;
  font-size: 16px;
}
.section__tabs-grid-item-special-text.membership {
  top: 30px;
  left: 25px;
}
.section__hero-banner-texture.mobile {
  display: none;
}
.section__hero-banner-texture.web {
  display: block;
}
.section__hero-banner-grid.mob {
  display: none;
}
.section__hero-banner-grid.web {
  display: block;
}

@media (max-width: 992px) {
  .section__hero-banner-texture.mobile {
    display: block;
  }
  .section__hero-banner-texture.web {
    display: none;
  }
  .section__hero-banner-grid.mob {
    display: block;
  }
  .section__hero-banner-grid.web {
    display: none;
  }
}

.mob-menu-container {
  display: none;
}

@media (max-width: 992px) {
  .mob-menu-container {
    display: inline-block;

    &.open {
      .mob-menu {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transform: translateY(0);
      }
      .bg-overlay {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }

      @for $i from 1 through 10 {
        .mob-menu ul li:nth-child(#{$i}) {
          transition: opacity 0.4s $i * 0.03s, translate 0.4s $i * 0.03s;
          opacity: 1;
          translate: 0 0;
        }
      }
    }
  }
  .mob-menu {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-40px);
    pointer-events: none;
    overflow-y: auto;
    min-height: 338px;
    width: 100%;
    top: 101px;
    left: 0;
    border-radius: 0;
    position: absolute;
    background-color: #fff;
    border-top: 1px solid rgba(3, 10, 33, 0.1);
    border-bottom: 1px solid rgba(3, 10, 33, 0.1);
    border-radius: 0 0 12px 12px;
    transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);

    @media (max-width: 992px) {
      .section__navbar-link {
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
      }
      .section__navbar-button {
        display: flex !important;
        width: 100%;
        padding: 12px;
        height: 41px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.8px;
      }
    }

    ul {
      padding: 24px;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 12px;
      li {
        list-style: none;
        padding: 8px 16px !important;
        translate: 0 -20px;

        display: flex;
        justify-content: space-between;
        align-items: center;
        :last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  ul.top-list {
    border-top: 1px solid rgba(3, 10, 33, 0.1);
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-top: 29px;
    li {
      margin-bottom: 0;
      padding: 0 !important;
    }
  }
  .bg-overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    top: 101px;
    left: 0;
    height: 100vh;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1);
  }
}

.not-found-btn {
  height: 53px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  display: grid;
  place-content: center;
  width: 100%;
  border-radius: 12px;
  background-color: #ffffff;
  font-family: inherit;
  color: $color--bottle-green !important;

  &-mob {
    height: 44px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
  }
}

.desktop-404 {
  display: block !important;

  @media (max-width: 992px) {
    display: none !important;
  }
}

.mobile-404 {
  display: none !important;

  @media (max-width: 992px) {
    display: flex !important;
  }
}

.membership__modal-btns {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.membership__modal-btn {
  height: 41px;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: all 0.4s;
  background-color: transparent;
  font-family: inherit;
  font-feature-settings: unset;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  text-align: left;
  padding: 12px;

  &:hover {
    background-color: #f2f3f4;
  }

  &.active {
    background-color: #f2f3f4;
    border-color: #002726;
  }
}
