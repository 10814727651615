@import './colors.scss';

.D_B_1_M_16_160 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    font-display: swap;
}

.D_B_2_R_14_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-display: swap;
}

.D_B_1_R_16_160 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    font-display: swap;
}

.D_B_1_R_16_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-display: swap;
}

.D_H_1_M_20_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-display: swap;
}

.D_H_1_S_20_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    font-display: swap;
}

.C_D_H_1_M_32_140 {
    font-family: var(--font-ibm-plex-sans-condensed);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    font-display: swap;
}

.C_D_H_2_M_24_120 {
    font-family: var(--font-ibm-plex-sans-condensed);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-display: swap;
}

.C_M_H_2_M_20_120 {
    font-family: var(--font-ibm-plex-sans-condensed);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-display: swap;
}

.LP_P_P_S {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    font-display: swap;
}

.LP_Pre_Pre {
    font-family: var(--font-ibm-plex-sans-condensed);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    font-display: swap;
    letter-spacing: 0.72px;
    text-transform: uppercase;
}

.D_C_R_12_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-display: swap;
}

.D_B_1_M_16_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-display: swap;
}

.D_B_2_M_14_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-display: swap;
}

.D_H_3 {
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-family: var(--font-ibm-plex-sans);
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    font-display: swap;
    /* 38px */
    letter-spacing: -0.76px;
}

.D_H_2 {
    font-family: var(--font-ibm-plex-sans-condensed);
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 135%;
    font-display: swap;
}

.D_H_1 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-display: swap;
}

.M_B_2_M_14_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-display: swap;
}

.M_B_2_R_14_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-display: swap;
}

.C_M_H_1_M_24_140 {
    font-family: var(--font-ibm-plex-sans-condensed);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    font-display: swap;
}

.D_C_14_120_120_10_L_S {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-display: swap;
    /* 16.8px */
    letter-spacing: 1.4px;
    text-transform: uppercase;
}

.M_B_1_M_16_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-display: swap;
}

.M_B_2_R_14_160 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    font-display: swap;
}

.M_B_1_M_16_160 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    font-display: swap;
}

.M_B_2_M_14_160 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    font-display: swap;
}

.L_I_C_L {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-display: swap;
}

.L_C_R {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-display: swap;
}

.L_C_L {
    font-family: var(--font-ibm-plex-sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    font-display: swap;
}

.L_P_T_R {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    font-display: swap;
}

.B_1_M_16_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-display: swap;
}

.D_Body_3 {
    color: #000;

    font-feature-settings: 'ss04' on;
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    font-display: swap;
}

.M_C_R_12_120_6 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    font-display: swap;
    letter-spacing: 0.72px;
    text-transform: uppercase;
}

.M_C_R_12_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-display: swap;
}

.M_Bread_Crumb {
    font-family: var(--font-ibm-plex-sans);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-display: swap;
}

.M_Bread_Crumb_2 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-display: swap;
}

.M_Heading {
    color: #030A21;
    font-family: var(--font-ibm-plex-sans-condensed);
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    font-display: swap;
}

.M_Heading_1 {
    color: #030A21;
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-family: var(--font-ibm-plex-sans);
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    font-display: swap;
    /* 38px */
    letter-spacing: -0.76px;
}

.M_Body_1 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    font-display: swap;
}

.M_Body_2 {
    color: #000;
    font-feature-settings: 'ss04' on;
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    font-display: swap;
}

.D_Body_2 {
    font-feature-settings: 'ss04' on;
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    font-display: swap;
}

.M_Caption {
    color: #030A21;
    font-family: var(--font-ibm-plex-sans);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-display: swap;
}

.D_Caption {
    color: #030A21;
    font-feature-settings: 'ss02' on;
    font-family: var(--font-ibm-plex-sans-condensed);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    font-display: swap;
    letter-spacing: 0.84px;
    text-transform: uppercase;
}

.M_H_1_S_20_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    font-display: swap;
}

.M_H_1_S_20_160 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    font-display: swap;
}

.M_C_R_12_160 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    font-display: swap;
    color: #B3B5BC;
}

.Thumnbail_16_140 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    font-display: swap;
}

.M_Tags {
    color: #030A21;
    font-feature-settings: 'ss02' on;
    font-family: var(--font-ibm-plex-sans-condensed);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    font-display: swap;
    /* 12px */
    letter-spacing: 0.72px;
    text-transform: uppercase;
}

.newsletter {
    color: #000;
    font-family: var(--font-ibm-plex-sans);
    font-size: 16.2px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    font-display: swap;
    /* 19.44px */
    letter-spacing: 3.888px;
    text-transform: capitalize;
}

.top_1 {
    color: #000;
    font-family: var(--font-ibm-plex-sans);
    font-size: 23.928px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    font-display: swap;
    /* 28.714px */
    letter-spacing: 2.154px;
}

// Homepage styles

.Display_1_M_70_130 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 70px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -1.5px;
}

.Display_2_M_40_130 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 40px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -1.55px;
}

.Display_3_M_36_130 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 36px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -1.55px;
}

.Display_4_M_34_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 34px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -1.55px;
}

.Display_5_M_32_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 32px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -1.55px;
}

.Heading_1_M_24_130 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.5%;
}


.Heading_2_M_20_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0%;
}

.Body_1_M_16_130 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0%;
}

.Body_1_M_16_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0%;
}

.Body_1_R_16_160 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0%;
}

.Body_1_R_16_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0%;
}

.Body_2_M_14_140 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0%;
}

.Body_2_M_14_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0%;
}

.Body_2_R_14_160 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0%;
}

.Body_2_R_14_140 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0%;
}

.Body_2_R_14_135 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: 3.55px;
}

.Body_2_R_14_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0%;
}

.Body_3_R_12_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}


.Caption_R_12_140 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0%;
}

.Caption_R_12_120 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 4.2px;
}


.Display_M_28_135 {
    font-family: var(--font-ibm-plex-sans);
    font-size: 28px;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -1.55px;
}

.Body_1_M_16_120_ALLCAPS {
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0%;
    text-transform: capitalize;
}


.Body_2_R_14_120_ALLCAPS {
    font-family: var(--font-ibm-plex-sans);
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0%;
    text-transform: capitalize;
}

.Caption_R_12_120_ALL_CAPS {
    font-family: var(--font-ibm-plex-sans);
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 4.2px;
    text-transform: capitalize;
}

.Desktop_Body_1_M_16_130{
    font-family: var(--font-ibm-plex-sans);
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
}

.Mobile_Display_M_28_130{
    font-family: var(--font-ibm-plex-sans);
    font-size: 28px;
    font-weight: 500;
    line-height: 130%;
    font-display: swap;
}

@media screen and (max-width: 768px) {
    .Display_2_M_40_130,
    .Display_3_M_36_130 {
        font-size: 28px;
    }

    .Heading_1_M_24_130 {
        font-size: 20px;
        letter-spacing: 0%;
        line-height: 120%;
    }

    .Heading_2_M_20_120 {
        font-size: 16px;
    }

    .Body_1_M_16_120,
    .Body_1_R_16_160 {
        font-size: 14px;
    }

    .Body_2_M_14_120,
    .Body_2_R_14_140,
    .Body_3_R_12_120 {
        font-size: 12px;
    }

    .Body_2_R_14_120 {
        line-height: 160%;
    }

    .Caption_R_12_120 {
        font-size: 14px;
        letter-spacing: 0%;
    }

    .Body_1_R_16_120 {
        font-size: 14px;
        line-height: 160%;
    }
}

.color_black {
    color: black;
}

.color_white {
    color: white;
}

.color_grey_one {
    color: #686C7A;
}

.color_grey_two {
    color: #353B4D;
}

.color_black_two {
    color : #030A21
}

.color_black_two_opacity {
    color:  #030A2180
}

.color_grey_three {
    color: #B3B5BC;
}

.color_red {
    color: #DB0000;
}

.align_center {
    text-align: center;
}

.opacity_half {
    opacity: 0.5;
}

.cursor_pointer {
    cursor: pointer;
}