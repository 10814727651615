@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import "../components/new//new.styles.scss";
@import "../components/new//new.styles.scss";

@import "../../src/projectStyles/index.scss";

* {
  box-sizing: border-box;
  font-feature-settings: 'ss02' on, 'ss01' on;
}

body {
  font-family: var(--font-ibm-plex-sans);
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
a {
  font-feature-settings: 'ss02' on, 'ss01' on;
}

h1,
h2,
h3,
h4,
h5,
p,
body {
  margin: unset !important;
}

// :root {
//   /* font-size set to 62.5% for easy px to rem conversion */

//   font-size: 62.5%;
//   /* 10px default rem */

//   /* Typography family */
//   --PLEX_SANS: IBMPlexSans, sans-serif;
//   --PLEX_SANS_CONDENSED: IBMPlexSansCondensed, sans-serif;

//   /* Typography Weights */
//   --REGULAR: 400;
//   --MEDIUM: 500;
//   --SEMI_BOLD: 600;

//   /* Typography Font sizes */
//   /* Leaving px values for reference */
//   /* --F12: 12px;
//   --F14: 14px;
//   --F16: 16px;
//   --F20: 20px;
//   --F24: 24px;
//   --F32: 32px; */

//   --F12: 12px;
//   --F14: 14px;
//   --F16: 16px;
//   --F18: 18px;
//   --F20: 20px;
//   --F22: 22px;
//   --F24: 24px;
//   --F26: 26px;
//   --F28: 28px;
//   --F30: 30px;
//   --F32: 32px;
//   --F34: 34px;
//   --F36: 36px;
//   --F40: 40px;
//   --F70: 70px;

//   /* Typography Line Heights */
//   /* Leaving px values for reference */
//   /* --L0: 1;
//   --L1: 44.8px;
//   --L2: 33.6px;
//   --L3: 32px;
//   --L4: 28.8px;
//   --L5: 25.6px;
//   --L6: 24px;
//   --L7: 22.4px;
//   --L8: 20px;
//   --L9: 19.2px;
//   --L10: 16.8px;
//   --L11: 16px;
//   --L12: 14.4px;
//   --L13: 12px; */

//   /* --L0: 1;
//   --L1: 4.48rem;
//   --L2: 3.36rem;
//   --L3: 3.2rem;
//   --L4: 2.88rem;
//   --L5: 2.56rem;
//   --L6: 2.4rem;
//   --L7: 2.24rem;
//   --L8: 2rem;
//   --L9: 1.92rem; */
//   --L10: 100%;
//   --L11: 110%;
//   --L12: 120%;
//   --L13: 130%;
//   --L14: 140%;
//   --L15: 150%;
//   --L16: 160%;

//   /* Typography Letter Spacing */
//   --LS5: .5%;
//   --LS15: -1.5%;
//   --LS155: -1.55%;
//   --LS42: 4.2%;
// }

// // Typography classes

// // Desktop
// .Display_1_M_70_130 {
//   font-family: var(--PLEX_SANS);
//   font-weight: var(--MEDIUM);
//   font-size: var(--F70);
//   letter-spacing: var(--LS15);
//   line-height: var(--L13);
// }

// .Display_2_M_40_130 {
//   font-family: var(--PLEX_SANS);
//   font-weight: var(--MEDIUM);
//   font-size: var(--F40);
//   letter-spacing: var(--LS155);
//   line-height: var(--L13);
// }

// .Display_3_M_36_130 {
//   font-family: var(--PLEX_SANS);
//   font-weight: var(--MEDIUM);
//   font-size: var(--F36);
//   letter-spacing: var(--LS155);
//   line-height: var(--L13);
// }

// .Display_4_M_34_120 {
//   font-family: var(--PLEX_SANS);
//   font-weight: var(--MEDIUM);
//   font-size: var(--F34);
//   letter-spacing: var(--LS155);
//   line-height: var(--L12);
// }

// .Display_5_M_32_120 {
//   font-family: var(--PLEX_SANS);
//   font-weight: var(--MEDIUM);
//   font-size: var(--F32);
//   letter-spacing: var(--LS155);
//   line-height: var(--L12);
// }

// .Heading_1_M_24_130 {
//   font-family: var(--PLEX_SANS);
//   font-weight: var(--MEDIUM);
//   font-size: var(--F24);
//   letter-spacing: var(--LS5);
//   line-height: var(--L13);
// }

// .Heading_2_M_20_120 {
//   font-family: var(--PLEX_SANS);
//   font-weight: var(--MEDIUM);
//   font-size: var(--F20);
//   line-height: var(--L12);
// }








/* Dropdown */

.dropdown {
  display: inline-block;
  position: relative;
  
  .dd-buttons {
    display: inline-block;
    height: 52px;
    padding: 16px 24px 16px 12px;
    flex-direction: column;
    align-items: flex-start;
    font-family: inherit;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #002726;
    cursor: pointer;
    width: 100%;
    color: #9a9da6;
  }
  
  .dd-buttons:after {
    content: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.33325 6.33398L5.99992 1.66732L10.6666 6.33398' stroke='%23002726' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%) rotate(180deg);
    transition: transform 0.3s;
  }
  
  .dd-buttons:has(+ .dd-input:checked)::after {
    transform: translateY(-50%) rotate(0deg);
  }
  
  .dd-buttons:has(+ .dd-input:checked) {
    border-radius: 8px 8px 0 0;
  }
}


.dd-input {
  display: none;
}

.dd-menu {
  // position: absolute;
  top: 92%;

  border-radius: 0 0 8px 8px;
  padding: 0;
  margin: 2px 0 0 0;

  background-color: #ffffff;
  list-style-type: none;
  width: 100%;
  z-index: 9;
}

.dd-input+.dd-menu {
  display: none;
}

.dd-input:checked+.dd-menu {
  display: block;
}

.dd-menu li {
  padding: 17px;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 1px solid #9a9da6;
}

.dd-menu li:hover {
  background-color: rgba(3, 10, 33, 0.1);
}

.dd-menu li a {
  display: block;
  margin: -10px -20px;
  padding: 10px 20px;
}

.dd-menu li.divider {
  padding: 0;
  // border-bottom: 1px solid #cccccc;
}

.blend-top {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  z-index: 9;
  max-width: 416px !important;
  object-fit: cover;
}

.blend-bottom {
  position: absolute;
  bottom: 19px;
  left: 0;
  max-width: 416px !important;
  z-index: 9999;
  object-fit: cover;
}

.nsw {
  display: flex;
  align-items: center;
  gap: 4px;
}

@media (max-width: 576px) {

  .nsw {
    font-size: 12px;
    font-weight: 400;
  }
}

.colored-text {
  color: #33C375;
}

img {
  text-indent: -10000px;
}

.anchor-card {
  display: flex;
}

// .self-placed-card-top{
//   gap: 16px;
//   display: flex;
//   flex-direction: column;
// }